import React from "react";
import { Handle, Position } from "react-flow-renderer";

export default class Noop extends React.Component {
  state = {};

  render() {
    return (
      <div className="cst-node">
        <Handle
          type="target"
          position={Position.Left}
          style={{ background: "#555" }}
        />
        <div className="cst-node__title">Noop</div>
      </div>
    );
  }
}
