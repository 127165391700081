import React, {
  MouseEvent as ReactMouseEvent,
  ReactElement,
  useEffect,
  useRef,
  useState,
} from "react";
import ReactFlow, {
  ReactFlowProvider,
  Background,
  Controls,
  Node as NodeReact,
  Edge as EdgeReact,
  OnLoadParams as ReactFlowOnLoadParams,
  FlowElement,
  Connection,
  FlowTransform,
} from "react-flow-renderer";
import { Node, NodeType } from "../../models/editor/Node";
import { NodeLink } from "../../models/editor/NodeLink";
import { nodeTypes } from "../../models/editor/NodeTypes";
import BuilderNodeDetails from "./BuilderNodeDetails";
import BuilderNodeSelector from "./BuilderNodeSelector";
import {
  setNodeSelected,
  setNodeBeingEdited,
  resetFlowEditor,
  removeNode,
} from "../../features/flowEditor/flowEditorSlice";
import {
  addNodeThunk,
  updateNodeThunk,
  addEdgeThunk,
  loadSamplesThunk,
  loadFlowVersionConfigThunk,
} from "../../features/flowEditor/thunks";
import {
  convertLinkToNodeLink,
  convertNodeToNodeReact,
} from "../../helpers/reactFlowConverter";
import { edgeTypes } from "../../models/editor/EdgeTypes";
import BuilderToolbar from "./BuilderToolbar";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { useParams } from "react-router";
import { convertToOriginalUuid, generateUuidV4 } from "../../helpers/uuid";
import {
  generateSubscribeToFlowVersionConfig,
  GetFlowVersionOptions,
} from "../../_ws_/flowsVersionsService";
import { generateFlowVersionConfigHandlers } from "../../helpers/flowVersionConfigHandlers";
import { createNewNode } from "../../helpers/nodeFactory";
import BuilderSamplesSideBar from "./BuilderSamplesSidebar";
import HTTPTriggerEventPoup from "./nodes/httpTrigger/HTTPTriggerEventPopup";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { getEnv, isProduction } from "../../helpers/isProduction";

interface EditorState {
  height: number;
  x: number;
  y: number;
  zoom: number;
}

export default function BuilderEditor(): ReactElement {
  const dispatch = useAppDispatch();

  // Path params
  const params = useParams<{ flowUuid: string; versionUuid: string }>();
  const flowUuid = convertToOriginalUuid(params.flowUuid);
  const versionUuid = convertToOriginalUuid(params.versionUuid);

  // Global state account
  const jwt = useAppSelector((state) => state.account.jwt);
  const currentWorkspace = useAppSelector(
    (state) => state.account.currentWorkspace
  );

  // Global state editor
  const nodes = useAppSelector((state) => state.flowEditor.nodes);
  const flowVersion = useAppSelector((state) => state.flowEditor.flowVersion);
  const reactElements = useAppSelector((state) => [
    ...state.flowEditor.nodes.map(convertNodeToNodeReact),
    ...state.flowEditor.nodeLinks.map(convertLinkToNodeLink),
  ]);

  // Local state
  const [editor, setEditor] = useState<EditorState>({
    height: 0,
    x: 0,
    y: 0,
    zoom: 1,
  });

  const [reactFlowInstance, setReactFlowInstance] =
    useState<ReactFlowOnLoadParams>();

  // References
  const flowContainer = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const navbar = document.getElementById("builder-navbar");
    setEditor((e) => ({
      ...e,
      height:
        window.innerHeight - (navbar?.getBoundingClientRect()?.height ?? 0),
    }));
    return () => {
      dispatch(resetFlowEditor());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!jwt || !currentWorkspace) {
      return;
    }

    const getFlowConfig = async (): Promise<void> => {
      try {
        await Promise.all([
          dispatch(
            loadFlowVersionConfigThunk({
              jwt,
              workspaceUuid: currentWorkspace.uuid,
              flowUuid,
              flowVersionUuid: versionUuid,
            })
          ),
          dispatch(
            loadSamplesThunk({
              userJWT: jwt,
              options: {
                flowUuid,
                flowVersionUuid: versionUuid,
                workspaceUuid: currentWorkspace.uuid,
              },
            })
          ),
        ]);
        // const samples = await listSamplesOfFlowVersion(jwt, {
        //   workspaceUuid: currentWorkspace.uuid,
        //   flowUuid,
        //   flowVersionUuid: versionUuid,
        // });
        // window.debug && console.log("Samples", samples.body);
        // dispatch(setSamples(samples.body));
      } catch (error) {
        window.debug &&
          console.error("Failed retrieving flow version config", error);
      }
    };
    getFlowConfig();
  }, [jwt, currentWorkspace, flowUuid, versionUuid, dispatch]);

  // Subscribe to event
  useEffect(() => {
    if (!jwt || !currentWorkspace) {
      return;
    }
    const options: GetFlowVersionOptions = {
      uuid: versionUuid,
      flowUuid,
      workspaceUuid: currentWorkspace.uuid,
    };
    const handlers = generateFlowVersionConfigHandlers(dispatch);
    window.debug && console.log(`GENERATED HANDLERS ${handlers}`);
    const subscriptionHandler = generateSubscribeToFlowVersionConfig(
      jwt,
      options,
      handlers
    ).subscribe();

    return () => {
      subscriptionHandler.unsubscribe();
    };
  }, [jwt, currentWorkspace, flowUuid, versionUuid, dispatch]);

  // Fit view at load
  useEffect(() => {
    if (reactFlowInstance) {
      reactFlowInstance.fitView();
    }
  }, [reactFlowInstance, flowVersion]);

  if (!jwt || !currentWorkspace) {
    return <div></div>;
  }

  // Callbacks
  const onReactFlowLoad = (reactFlowInstance: ReactFlowOnLoadParams): void => {
    setReactFlowInstance(reactFlowInstance);
    // reactFlowInstance.fitView();
  };

  const onReactFlowConnect = async (
    connection: EdgeReact | Connection
  ): Promise<boolean> => {
    if (!connection.source || !connection.target) {
      return false;
    }

    if (!flowVersion) {
      throw new Error("No flow version");
    }

    if (flowVersion.state === "LIVE") {
      throw new Error("Operation not permitted on LIVE flow version");
    }

    const newConnection: NodeLink = {
      uuid: generateUuidV4(),
      flowVersionUuid: flowVersion.uuid,
      nodeFromUuid: connection.source,
      sourceHandle: connection.sourceHandle,
      nodeToUuid: connection.target,
      targetHandle: connection.targetHandle,
    };

    await dispatch(
      addEdgeThunk({
        userJWT: jwt,
        options: {
          flowUuid,
          flowVersionUuid: versionUuid,
          uuid: newConnection.uuid,
          workspaceUuid: currentWorkspace.uuid,
        },
        body: newConnection,
      })
    );

    return false;
  };

  const onReactFlowDragOver = (event: React.DragEvent): void => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  };

  const onReactFlowDrop = async (event: React.DragEvent): Promise<void> => {
    event.preventDefault();

    if (!flowVersion) {
      throw new Error("No flow version");
    }

    if (flowVersion.state === "LIVE") {
      throw new Error("Operation not permitted on LIVE flow version");
    }

    const flowContainerCur = flowContainer.current;
    if (reactFlowInstance && flowContainerCur) {
      const reactFlowBounds = flowContainerCur.getBoundingClientRect();
      const type = event.dataTransfer.getData(
        "application/reactflow"
      ) as NodeType;
      const position = reactFlowInstance.project({
        x: event.clientX - reactFlowBounds.left - 50,
        y: event.clientY - reactFlowBounds.top - 50,
      });
      const newNode: Node = createNewNode(versionUuid, type, position);

      try {
        await dispatch(
          addNodeThunk({
            userJWT: jwt,
            options: {
              flowUuid,
              flowVersionUuid: versionUuid,
              uuid: newNode.uuid,
              workspaceUuid: currentWorkspace.uuid,
            },
            body: newNode,
          })
        );
      } catch (error) {
        window.debug && console.error("Failed to add node ", error);
        dispatch(removeNode(newNode.uuid));
      }
    }
  };

  const onReactFlowEdgeContextMenu = (
    event: ReactMouseEvent,
    element: FlowElement
  ): void => {
    // window.debug && console.log("onReactFlowEdgeContextMenu", event, element);
  };

  const onReactFlowElementClicked = (
    event: ReactMouseEvent,
    element: FlowElement
  ): void => {
    // window.debug && console.log("onReactFlowElementClicked", event, element);
  };

  const onReactFlowOnSelectionChange = (
    elements: FlowElement[] | null
  ): void => {
    // window.debug && console.log("onReactFlowOnSelectionChange", elements);
    if (
      (elements && elements.length === 0) ||
      (elements && elements[0].type === "edge")
    ) {
      dispatch(setNodeSelected(null));
      dispatch(setNodeBeingEdited(null));
      return;
    }
    // window.debug && console.log("onReactFlowOnSelectionChange");
    // window.debug && console.log(elements);
    const nodeReactSelected = elements !== null ? elements[0] : null;
    const uuid = nodeReactSelected ? nodeReactSelected.id : null;
    let nodeBackendSelected = null;
    if (uuid) {
      const potentialBackendNode = nodes.find((v) => v.uuid === uuid);
      nodeBackendSelected = potentialBackendNode ? potentialBackendNode : null;
    }
    dispatch(
      setNodeSelected(nodeBackendSelected ? nodeBackendSelected.uuid : null)
    );
    dispatch(setNodeBeingEdited(null));
  };

  const onReactFlowPaneClick = (event: ReactMouseEvent): void => {
    // window.debug && console.log("onReactFlowPaneClick");
    // window.debug && console.log(event);
  };

  const onReactFlowMoveEnd = (
    flowTransform: FlowTransform | undefined
  ): void => {
    if (flowTransform) {
      setEditor({
        ...editor,
        x: flowTransform.x,
        y: flowTransform.y,
        zoom: flowTransform.zoom,
      });
    }
  };

  const onReactFlowNodeDragStop = async (
    event: ReactMouseEvent,
    node: NodeReact
  ): Promise<void> => {
    if (!flowVersion) {
      throw new Error("No flow version");
    }

    if (flowVersion.state === "LIVE") {
      throw new Error("Operation not permitted on LIVE flow version");
    }
    // window.debug && console.log("onReactFlowNodeDragStop");
    // window.debug && console.log(event, node);
    if (node) {
      let currentNode = nodes.find((v) => v.uuid === node.id);
      if (currentNode) {
        currentNode = {
          ...currentNode,
          positionX: Math.round(node.position.x),
          positionY: Math.round(node.position.y),
        };
        await dispatch(
          updateNodeThunk({
            userJWT: jwt,
            options: {
              flowUuid,
              flowVersionUuid: versionUuid,
              uuid: currentNode.uuid,
              workspaceUuid: currentWorkspace.uuid,
            },
            body: currentNode,
          })
        );
      }
    }
  };

  // const saveButtonClicked = async () => {
  //   window.debug && console.log("Save clicked");
  //   if (!flowVersion) {
  //     throw new Error("No flow version in state");
  //   }
  //   if (!savingConfig) {
  //     wsSaveFlowVersionConfig(
  //       jwt,
  //       { workspaceUuid: currentWorkspace.uuid, flowUuid, uuid: versionUuid },
  //       {
  //         nodes,
  //         edges: nodeLinks,
  //         flowVersion,
  //       }
  //     );
  //     setSavingConfig(true);
  //   }
  // };
  // End callbacks

  const docTitle = isProduction()
    ? `Builder - ${flowVersion?.name}`
    : `Builder - ${flowVersion?.name} (${getEnv()})`;

  const isEditable = flowVersion?.state !== "LIVE";

  return (
    <HelmetProvider>
      <div
        className="builder-editor"
        // style={{ height: this.state.editor.height }}
        style={{
          height: `calc(${editor.height}px - 4.5rem)`,
          width: "calc(100vw)",
        }}
        ref={flowContainer}
      >
        <Helmet>
          <title>{docTitle}</title>
        </Helmet>
        <ReactFlowProvider>
          <ReactFlow
            nodeTypes={nodeTypes}
            edgeTypes={edgeTypes}
            elements={reactElements}
            selectNodesOnDrag={false}
            snapToGrid={true}
            nodesDraggable={isEditable}
            nodesConnectable={isEditable}
            onLoad={onReactFlowLoad}
            onDragOver={onReactFlowDragOver}
            onDrop={onReactFlowDrop}
            onConnect={onReactFlowConnect}
            onEdgeContextMenu={onReactFlowEdgeContextMenu}
            // onElementClick={onReactFlowElementClicked}
            onSelectionChange={onReactFlowOnSelectionChange}
            // onPaneClick={onReactFlowPaneClick}
            onMoveEnd={onReactFlowMoveEnd}
            onNodeDragStop={onReactFlowNodeDragStop}
            maxZoom={1.2}
            minZoom={0.5}
            defaultZoom={1.0}
          >
            {/* <Background variant={BackgroundVariant.Lines} gap={24} size={2} /> */}
            <Background style={{ backgroundColor: "#eaeaea" }} />
            <Controls
              showInteractive={false}
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            />
          </ReactFlow>
        </ReactFlowProvider>
        <BuilderToolbar flowVersion={flowVersion}></BuilderToolbar>
        <BuilderNodeSelector flowVersion={flowVersion}></BuilderNodeSelector>
        <BuilderSamplesSideBar
          flowVersion={flowVersion}
        ></BuilderSamplesSideBar>
        <BuilderNodeDetails></BuilderNodeDetails>
        <HTTPTriggerEventPoup flowVersion={flowVersion}></HTTPTriggerEventPoup>
        {/* <BuilderMiniToolbar editorState={editor}></BuilderMiniToolbar> */}
      </div>
    </HelmetProvider>
  );
}
