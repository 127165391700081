import { Action, createSlice } from "@reduxjs/toolkit";
import { WSEvent } from "../../_ws_";

export interface HttpTriggerSliceState {
  payload: WSEvent | undefined;
}

export const httpTriggerSlice = createSlice({
  name: "httpTrigger",
  initialState: {
    payload: undefined,
  } as HttpTriggerSliceState,
  reducers: {
    setHTTPTriggerPayload: (
      state: HttpTriggerSliceState,
      action: Action & {
        payload: WSEvent | undefined;
      }
    ) => {
      state.payload = action.payload;
    },
  },
});

export const { setHTTPTriggerPayload } = httpTriggerSlice.actions;
