import React from "react";
import { Handle, Position } from "react-flow-renderer";

export default class Branching extends React.Component {
  state = {};

  render() {
    return (
      <div className="cst-node">
        <Handle
          type="target"
          position={Position.Left}
          style={{ background: "#555" }}
        />
        <div className="cst-node__title">Branching</div>
        <Handle
          id="success"
          type="source"
          position={Position.Right}
          style={{ background: "#555", top: 10 }}
        />
        <Handle
          id="failed"
          type="source"
          position={Position.Right}
          style={{ background: "#555", top: 25 }}
        />
      </div>
    );
  }
}
