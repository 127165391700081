import Editor, { useMonaco } from "@monaco-editor/react";
import { editor } from "monaco-editor";
import { ReactElement, useEffect } from "react";
import { registerMonacoJsonata } from "../../helpers/monaco/addJsonataMonaco";

export function MonacoJsonEditor({
  readOnly,
  value,
  onChange,
  path,
  size,
}: {
  readOnly?: boolean;
  value: string;
  onChange?: (value: string | undefined) => void;
  path?: string;
  size?: {
    height?: string;
    width?: string;
  };
}): ReactElement {
  // Init monaco
  const monaco = useMonaco();
  useEffect(() => {
    if (monaco) {
      registerMonacoJsonata(monaco);
    }
  }, [monaco]);

  function onMount(editor: editor.IStandaloneCodeEditor) {
    editor.getAction("editor.action.formatDocument").run();
  }

  return monaco ? (
    <Editor
      height={size?.height}
      width={size?.width}
      options={{
        minimap: { enabled: false },
        lineNumbers: "off",
        readOnly: readOnly ?? false,
        wordWrap: "on",
        wrappingIndent: "same",
        scrollBeyondLastLine: false,
        renderValidationDecorations: "on",
        showFoldingControls: "always",
      }}
      onMount={onMount}
      defaultLanguage="json"
      value={value}
      onChange={onChange}
      path={path}
    />
  ) : (
    <div></div>
  );
}
