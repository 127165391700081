import { ReactElement, useEffect } from "react";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import TopBar from "./components/bars/topBar/TopBar";
import Dashboard from "./pages/backoffice/dashboard/Dashboard";
import FlowsList from "./pages/backoffice/flowsList/FlowsList";
import FlowCreate from "./pages/backoffice/flowCreate/FlowCreate";
import FlowDetails from "./pages/backoffice/flowDetails/FlowDetails";
import WorkspaceSettings from "./pages/backoffice/workspace/WorkspaceSettings";
import UserSettings from "./pages/backoffice/user/UserSettings";
import FlowBuilder from "./pages/backoffice/flowBuilder/FlowBuilder";
import { useAppSelector } from "./hooks";
import { User } from "./models/accounts/User";
import { Workspace } from "./models/accounts/Workspace";
import { wsGetMyInfos, wsGetMyWorkspaces } from "./_ws_/accountsService";
import { wsInstance, WSResponse } from "./_ws_";
import {
  setUser,
  setWorkspacesAvailable,
} from "./features/account/accountSlice";
import WorkspaceCreate from "./pages/backoffice/workspace/WorkspaceCreate";
import { PopupConnectionLost } from "./components/popups/ConnectionLostPopup";

export function Backoffice(): ReactElement {
  const location = useLocation();
  const dispatch = useDispatch();
  let protectedRoutes;

  const userJWT = useAppSelector((state) => state.account.jwt);

  if (userJWT) {
    protectedRoutes = (
      <>
        <Route exact path="/" component={Dashboard} />
        <Route exact path="/flows" component={FlowsList} />
        <Route exact path="/flows/n" component={FlowCreate} />
        <Route exact path="/flows/v/:flowUuid" component={FlowDetails} />
        <Route
          exact
          path="/flows/:flowUuid/versions/:versionUuid/b"
          component={FlowBuilder}
        />
        <Route exact path="/workspaces/n" component={WorkspaceCreate} />
        <Route exact path="/settings" component={WorkspaceSettings} />
        <Route exact path="/me/settings" component={UserSettings} />
      </>
    );
  } else {
    protectedRoutes = (
      <>
        <Route path="/">
          <Redirect
            to={{
              search: `?redirectTo=${encodeURIComponent(location.pathname)}`,
              pathname: `/login`,
            }}
          />
        </Route>
      </>
    );
  }

  useEffect(() => {
    if (userJWT) {
      const retrieveUserInfos = async (jwt: string) => {
        try {
          const userInfos = await wsGetMyInfos(jwt);
          dispatch(setUser(userInfos));
        } catch (error) {
          window.debug && console.error("List flows - error received", error);
        }
      };
      retrieveUserInfos(userJWT);

      const retrieveUserWorkspaces = async (jwt: string) => {
        try {
          const workspaces = await wsGetMyWorkspaces(jwt);
          dispatch(setWorkspacesAvailable(workspaces));
        } catch (error) {
          window.debug && console.error("List flows - error received", error);
        }
      };
      retrieveUserWorkspaces(userJWT);

      // const getInfosActionName = "GET /v1/me/infos";
      // const wsInfosResponseHandler = (wsMessage: WSResponse) => {
      //   if (wsMessage.statusCode === 200) {
      //     const content = wsMessage.body as User;
      //     dispatch(setUser(content));
      //   } else {
      //     console.error("Get user infos - error received", wsMessage);
      //   }
      //   wsInstance.removeListener(getInfosActionName, wsInfosResponseHandler);
      // };
      // wsInstance.on(getInfosActionName, wsInfosResponseHandler);
      // wsGetMyInfos(userJWT);

      // const getWorkspacesActionName = "GET /v1/me/workspaces";
      // const wsWorkspacesResponseHandler = (wsMessage: WSResponse) => {
      //   if (wsMessage.statusCode === 200) {
      //     const content = wsMessage.body as Workspace[];
      //     dispatch(setWorkspacesAvailable(content));
      //   } else {
      //     console.error("Get workspaces - error received", wsMessage);
      //   }
      //   wsInstance.removeListener(
      //     getWorkspacesActionName,
      //     wsWorkspacesResponseHandler
      //   );
      // };
      // wsInstance.on(getWorkspacesActionName, wsWorkspacesResponseHandler);
      // wsGetMyWorkspaces(userJWT);

      // // Cleaner
      // return () => {
      //   wsInstance.removeListener(getInfosActionName, wsInfosResponseHandler);
      //   wsInstance.removeListener(
      //     getWorkspacesActionName,
      //     wsWorkspacesResponseHandler
      //   );
      // };
    }
  }, [userJWT, dispatch]);

  return (
    <div className="main-layout__container">
      {/* <div className="main-layout__left-bar">
        <LeftBar></LeftBar>
      </div> */}
      <div className="main-layout__top-bar">
        <TopBar></TopBar>
      </div>
      <div className="main-layout__content">
        <Switch>{protectedRoutes}</Switch>
      </div>
      <PopupConnectionLost></PopupConnectionLost>
    </div>
  );
}
