import { add, compareAsc, format, parseISO } from "date-fns";
import EventEmitter from "events";
import { setConnectionStatus } from "../features/account/accountSlice";
import { generateUuidV4 } from "../helpers/uuid";
import store from "../store";
import "./nodeOrchestratorService";

const WS_URL = process.env.REACT_APP_GATEWAY_WS_URL || "";

export type WSInput = WSPing | WSRequest | WSSubscription;

export interface WSPing {
  uuid: string;
  type: "ping";
}

export interface WSRequest {
  uuid: string;
  type: "request";
  content: {
    api: "accounts" | "flows" | "orchestrator";
    resourceName: string;
    path: string;
    method: string;
    options?: unknown;
    body?: unknown;
    userJWT?: string;
  };
}

export interface WSSubscription {
  uuid: string;
  type: "subscription";
  content: {
    action: "subscribe" | "unsubscribe";
    resourceType: string;
    resourceUuid: string;
    resourceParentUuid?: string;
    workspaceUuid: string;
    userJWT: string;
  };
}

export type WSOutput = WSPong | WSResponse | WSEvent;

export interface WSPong {
  uuid: string;
  type: "pong";
}

export interface WSResponse<T = unknown> {
  uuid: string;
  type: "response";
  method: string;
  path: string;
  statusCode: number;
  body: T;
}

export interface WSEvent {
  uuid: string;
  type: "event";
  eventName: string;
  resourceType: string;
  resourceUuid: string;
  resourceParentUuid?: string;
  workspaceUuid: string;
  body: unknown;
}

export interface ResponseError {
  statusCode: number;
  statusName: string;
  errorCode: string;
  message: string;
}

export class WebsocketInstance extends EventEmitter {
  socket?: WebSocket = undefined;
  connectTimeout?: NodeJS.Timeout = undefined;
  connectingPromise?: Promise<void> = undefined;
  pingInterval?: NodeJS.Timeout = undefined;

  constructor() {
    super();
    this.onMessage = this.onMessage.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onError = this.onError.bind(this);

    this.connect();
  }

  connect = async () => {
    if (!this.socket && !this.connectingPromise) {
      window.debug && console.log("Connect called");
      this.connectingPromise = new Promise<void>((resolve) => {
        this.connectTimeout = setTimeout(() => {
          window.debug && console.error("Socket connection timeout");
          this.closeSocket();
          resolve();
        }, 5000);

        this.socket = new WebSocket(`${WS_URL}/ws`);
        // this.socket = new WebSocket(`ws://localhost:9101/ws`);

        this.socket.onmessage = this.onMessage;
        this.socket.onclose = this.onClose;
        this.socket.onerror = this.onError;

        this.socket.onopen = () => {
          window.debug && console.log("WS connection open");
          if (this.connectTimeout) {
            clearTimeout(this.connectTimeout);
            this.connectTimeout = undefined;
          }
          this.connectingPromise = undefined;
          if (this.pingInterval) {
            clearInterval(this.pingInterval);
          }
          this.pingInterval = setInterval(this.sendPing, 15000);
          this.emit("connect");
          // Update store
          store.dispatch(
            setConnectionStatus({
              connectionStatus: "ONLINE",
              deconnectionTimestamp: format(
                new Date(),
                "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"
              ),
            })
          );
          // Finish update store
          resolve();
        };
      });
    }
    if (this.connectingPromise) {
      return this.connectingPromise;
    }
  };

  getSocket = async (): Promise<WebSocket | undefined> => {
    if (!this.socket || this.connectingPromise) {
      await this.connect();
    }
    return this.socket;
  };

  sendMessage = async (request: WSInput): Promise<void> => {
    const message = JSON.stringify(request);
    const socket = await this.getSocket();
    if (socket) {
      socket.send(message);
    } else {
      setImmediate(() => {
        this.emit(request.uuid, {
          type: "response",
          statusCode: 500,
        });
      });
    }
  };

  onError = (ev: Event): void => {
    window.debug && console.error("WS errored", ev);
    this.resetState();
  };

  onClose = (ev: CloseEvent): void => {
    window.debug && console.log("WS closed", ev);
    this.resetState();
  };

  closeSocket = (): void => {
    window.debug && console.log("Closing socket");
    if (this.socket) {
      try {
        this.socket.close();
      } catch (error) {}
    }
    this.resetState();
  };

  resetState = (): void => {
    window.debug && console.log("Reset state");
    if (this.socket) {
      this.socket.onclose = null;
      this.socket.onerror = null;
      this.socket.onmessage = null;
    }
    this.socket = undefined;
    this.connectingPromise = undefined;
    // Reset ping interval
    if (this.pingInterval) {
      clearInterval(this.pingInterval);
      this.pingInterval = undefined;
    }
    if (this.connectTimeout) {
      clearTimeout(this.connectTimeout);
      this.connectTimeout = undefined;
    }
    this.emit("close");

    // Update redux state
    const currentState = store.getState();
    if (
      currentState.account.connectionStatus !== "ONLINE" &&
      compareAsc(
        add(parseISO(store.getState().account.lastDeconnectionTimestamp), {
          seconds: 10,
        }),
        new Date()
      ) === -1
    ) {
      store.dispatch(
        setConnectionStatus({
          connectionStatus: "OFFLINE",
          deconnectionTimestamp: format(
            new Date(),
            "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"
          ),
        })
      );
    } else {
      store.dispatch(
        setConnectionStatus({
          connectionStatus: "RECONNECTING",
          deconnectionTimestamp: format(
            new Date(),
            "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"
          ),
        })
      );
    }
    // End update state

    // Launch connect again
    setTimeout(() => {
      window.debug && console.info("Retrying connect...");
      this.connect();
    }, 5000);
  };

  sendPing = () => {
    this.sendMessage({
      uuid: generateUuidV4(),
      type: "ping",
    });
  };

  onMessage = (messageEvent: MessageEvent): void => {
    const messageParsed = JSON.parse(messageEvent.data) as WSOutput;
    window.debug &&
      console.log(`Message "${messageParsed.type}"`, messageParsed);
    switch (messageParsed.type) {
      case "response": {
        const response = messageParsed;
        this.emit(response.uuid, response);
        break;
      }
      case "event": {
        const event = messageParsed;
        let key: string;
        if (event.resourceParentUuid) {
          key = `${event.resourceType}/${event.resourceParentUuid}/${event.resourceUuid}`;
        } else {
          key = `${event.resourceType}/${event.resourceUuid}`;
        }
        window.debug && console.log(`Emitting with key ${key}`);
        this.emit(key, event);
        break;
      }
      case "pong": {
        // Do nothing
        break;
      }
      default: {
        window.debug &&
          console.error(`Unknown message type ${messageParsed["type"]}`);
      }
    }
  };
}

export const wsInstance = new WebsocketInstance();
