import classNames from "classnames";
import { ReactElement, useState } from "react";
import { useDispatch } from "react-redux";
import { setSampleSelected } from "../../../../features/flowEditor/flowEditorSlice";
import { setHTTPTriggerPayload } from "../../../../features/flowEditor/httpTriggerSlice";
import {
  addNewSampleThunk,
  upsertSampleNodeThunk,
} from "../../../../features/flowEditor/thunks";
import { generateUuidV4 } from "../../../../helpers/uuid";
import { useAppSelector } from "../../../../hooks";
import { FlowVersion } from "../../../../models/editor/FlowVersion";
import { NodeType } from "../../../../models/editor/Node";
import { Sample, SampleState } from "../../../../models/editor/Sample";
import {
  SampleNode,
  SampleNodeBuildState,
} from "../../../../models/editor/SampleNode";
import { RadioButton } from "../../../common/inputs/RadioButton";

enum TriggerChoice {
  REPLACE_CURRENT = "REPLACE_CURRENT",
  REPLACE_ANOTHER = "REPLACE_ANOTHER",
  CREATE_NEW = "CREATE_NEW",
}

export default function HTTPTriggerEventPoup({
  flowVersion,
}: {
  flowVersion: FlowVersion | null;
}): ReactElement {
  const dispatch = useDispatch();
  const event = useAppSelector((state) => state.httpTrigger.payload);
  const jwt = useAppSelector((state) => state.account.jwt);
  let samples = useAppSelector((state) => state.flowEditor.samples);
  const sampleSelected = useAppSelector(
    (state) => state.flowEditor.sampleSelected
  );
  const nodes = useAppSelector((state) => state.flowEditor.nodes);
  const sampleNodes = useAppSelector((state) => state.flowEditor.sampleNodes);

  const [triggerChoice, setTriggerChoice] = useState(
    TriggerChoice.REPLACE_CURRENT
  );
  const [newSampleName, setNewSampleName] = useState("");

  if (event === undefined || !samples || !sampleSelected || !flowVersion) {
    return <></>;
  }

  samples = samples.filter((v) => v.uuid !== sampleSelected);

  function handleChangeRadio(value: string) {
    setTriggerChoice(value as TriggerChoice);
  }

  function handleOnDiscard() {
    dispatch(setHTTPTriggerPayload(undefined));
  }

  async function handleOnConfirm() {
    const httpTriggerNode = nodes.find((v) => v.type === NodeType.HTTP_TRIGGER);
    window.debug && console.log("HANDLE ON CONFIRM");
    window.debug && console.log(jwt, flowVersion, httpTriggerNode);
    if (jwt && flowVersion && event && httpTriggerNode) {
      switch (triggerChoice) {
        case TriggerChoice.REPLACE_CURRENT: {
          if (sampleNodes[httpTriggerNode.uuid] && sampleSelected) {
            const newNodeSample: SampleNode = {
              ...sampleNodes[httpTriggerNode.uuid],
              output: JSON.stringify(event.body),
              buildState: SampleNodeBuildState.SUCCESS,
            };
            await dispatch(
              upsertSampleNodeThunk({
                userJWT: jwt,
                options: {
                  flowUuid: flowVersion.flowUuid,
                  flowVersionUuid: flowVersion.uuid,
                  sampleUuid: sampleSelected,
                  workspaceUuid: flowVersion.workspaceUuid,
                },
                body: newNodeSample,
              })
            );
          }
          dispatch(setHTTPTriggerPayload(undefined));
          break;
        }
        case TriggerChoice.REPLACE_ANOTHER: {
          break;
        }
        case TriggerChoice.CREATE_NEW: {
          window.debug && console.log("CREATE_NEW");
          const newSample: Sample = {
            uuid: generateUuidV4(),
            flowVersionUuid: flowVersion.uuid,
            state: SampleState.ACTIVE,
            name: newSampleName,
          };
          await dispatch(
            addNewSampleThunk({
              userJWT: jwt,
              options: {
                flowUuid: flowVersion.flowUuid,
                flowVersionUuid: flowVersion.uuid,
                workspaceUuid: flowVersion.workspaceUuid,
              },
              body: newSample,
            })
          );
          await dispatch(
            upsertSampleNodeThunk({
              userJWT: jwt,
              options: {
                flowUuid: flowVersion.flowUuid,
                flowVersionUuid: flowVersion.uuid,
                workspaceUuid: flowVersion.workspaceUuid,
                sampleUuid: newSample.uuid,
              },
              body: {
                sampleUuid: newSample.uuid,
                nodeUuid: httpTriggerNode.uuid,
                buildState: SampleNodeBuildState.SUCCESS,
                flowVersionUuid: flowVersion.uuid,
                input: "",
                output: JSON.stringify(event.body),
              },
            })
          );
          dispatch(setSampleSelected(newSample.uuid));
          dispatch(setHTTPTriggerPayload(undefined));
          break;
        }
        default:
          window.debug &&
            console.error(`Unknown triggerChoice ${triggerChoice}`);
      }
    }
  }

  return (
    <div className="node-event-popup">
      <div className="node-event-popup__popup">
        <div className="node-event-popup__title">Received a new HTTP event</div>
        <div className="node-event-popup__content">
          <p className="node-event-popup__text">
            What do you want to do with it ?
          </p>
          <div className="node-event-popup__options">
            <div className="node-event-popup__option-item">
              <RadioButton
                name="trigger_choice"
                value={TriggerChoice.REPLACE_CURRENT}
                currentValue={triggerChoice}
                onChange={handleChangeRadio}
                label="Replace in current sample"
              ></RadioButton>
            </div>
            {/* <div className="node-event-popup__option-item">
              <RadioButton
                name="trigger_choice"
                value={TriggerChoice.REPLACE_ANOTHER}
                currentValue={triggerChoice}
                onChange={handleChangeRadio}
                label="Replace in another existing sample"
              ></RadioButton>
              <select
                disabled={triggerChoice !== TriggerChoice.REPLACE_ANOTHER}
              >
                {samples.map((s) => (
                  <option key={s.uuid}>{s.name}</option>
                ))}
              </select>
            </div> */}
            <div className="node-event-popup__option-item">
              <RadioButton
                name="trigger_choice"
                value={TriggerChoice.CREATE_NEW}
                currentValue={triggerChoice}
                onChange={handleChangeRadio}
                label="Create new sample"
              ></RadioButton>
              <input
                type="text"
                value={newSampleName}
                onChange={(e) => setNewSampleName(e.target.value)}
                disabled={triggerChoice !== TriggerChoice.CREATE_NEW}
              />
            </div>
          </div>
          <div className="node-event-popup__actions">
            <button onClick={handleOnDiscard}>Discard</button>
            <button onClick={handleOnConfirm}>Confirm</button>
          </div>
        </div>
      </div>
    </div>
  );
}
