import { ReactElement } from "react";
import { Link } from "react-router-dom";

export default function Dashboard(): ReactElement {
  return (
    <div className="dashboard">
      <div className="dashboard__grid">
        <div className="dashboard__grid-item">
          This page is still in construction.
        </div>
        <div className="dashboard__grid-item">
          It will show a summary of the current workspace.
        </div>
        <div className="dashboard__grid-item">
          Go to{" "}
          <Link
            to="/flows"
            style={{ textDecoration: "underline", cursor: "pointer" }}
          >
            flows
          </Link>
        </div>
        <div className="dashboard__grid-item">
          Please find the documentation at the following address.
        </div>
      </div>
    </div>
  );
}
