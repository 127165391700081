import { ReactElement, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { setFlowVersion } from "../../features/flowEditor/flowEditorSlice";
import { shortenUuid } from "../../helpers/uuid";
import { useAppSelector } from "../../hooks";
import { FlowVersion } from "../../models/editor/FlowVersion";
import { wsPromoteFlowVersion } from "../../_ws_/flowsVersionsService";
import { ButtonPrimary } from "../common/buttons/ButtonPrimary";
import { Chip } from "../common/chips/Chip";

export default function BuilderToolbar({
  flowVersion,
}: {
  flowVersion: FlowVersion | null;
}): ReactElement {
  const dispatch = useDispatch();

  const jwt = useAppSelector((state) => state.account.jwt);
  const currentWorkspace = useAppSelector(
    (state) => state.account.currentWorkspace
  );
  const saving = useAppSelector((state) => state.flowEditor.saving);

  const [editingName, setEditingName] = useState(false);

  const history = useHistory();

  if (!flowVersion || !jwt || !currentWorkspace) {
    return <div></div>;
  }

  const onClickBackButton = () => {
    history.push(`/flows/v/${shortenUuid(flowVersion.flowUuid)}`);
  };

  const onDoubleClickName = () => {
    setEditingName(true);
  };

  const publishVersion = async (flowVersion: FlowVersion) => {
    await wsPromoteFlowVersion(jwt, {
      flowUuid: flowVersion.flowUuid,
      uuid: flowVersion.uuid,
      workspaceUuid: currentWorkspace.uuid,
    });
    dispatch(
      setFlowVersion({
        ...flowVersion,
        state: "LIVE",
      })
    );
    console.log("Publish !");
  };

  const nameDom = !editingName ? (
    <span
      className="builder-toolbar__flow-name"
      onDoubleClick={onDoubleClickName}
    >
      {flowVersion.name}
    </span>
  ) : (
    <span>
      <input type="text" value={flowVersion.name} />
    </span>
  );

  const publishDom =
    flowVersion.state === "LIVE" ? (
      <Chip text="LIVE"></Chip>
    ) : (
      <ButtonPrimary
        text="Publish"
        onClick={() => publishVersion(flowVersion)}
      ></ButtonPrimary>
    );

  return (
    <div className="builder-toolbar">
      <div className="builder-toolbar__flex">
        <div className="builder-toolbar__flex-left">
          <button
            className="hktf-button hktf-button-text"
            onClick={onClickBackButton}
          >
            Back
          </button>
        </div>
        <div className="builder-toolbar__flex-middle">{nameDom}</div>
        <div className="builder-toolbar__flex-right">
          {saving ? "Saving" : "Saved"}
          {publishDom}
        </div>
      </div>
    </div>
  );
}
