import { generateUuidV4 } from "../helpers/uuid";
import { FlowVersion, FlowVersionConfig } from "../models/editor/FlowVersion";
import { WSRequest } from "./index";
import { EventHandlers, Subscription } from "./subscription";
import { sendWsRequest } from "./wsLikeHttp";

export interface ListFlowsVersionsOptions {
  flowUuid: string;
  workspaceUuid: string;
}

export interface GetFlowVersionOptions {
  uuid: string;
  flowUuid: string;
  workspaceUuid: string;
}

export const wsListFlowsVersions = async (
  userJWT: string,
  flowsOptions: ListFlowsVersionsOptions
): Promise<FlowVersion[]> => {
  const wsRequest: WSRequest = {
    uuid: generateUuidV4(),
    type: "request",
    content: {
      api: "flows",
      resourceName: "flowVersions",
      path: "/v1/flows/versions",
      method: "LIST",
      userJWT,
      options: flowsOptions,
    },
  };
  const result = await sendWsRequest<FlowVersion[]>(wsRequest);
  return result.body;
};

export const wsGetFlowVersion = async (
  userJWT: string,
  flowsOptions: GetFlowVersionOptions
): Promise<FlowVersion> => {
  const wsRequest: WSRequest = {
    uuid: generateUuidV4(),
    type: "request",
    content: {
      api: "flows",
      resourceName: "flowVersions",
      path: "/v1/flows/versions",
      method: "GET",
      userJWT,
      options: flowsOptions,
    },
  };
  const result = await sendWsRequest<FlowVersion>(wsRequest);
  return result.body;
};

export const wsCreateFlowVersion = async (
  userJWT: string,
  flowsOptions: ListFlowsVersionsOptions,
  newFlowVersion: FlowVersion
): Promise<void> => {
  const wsRequest: WSRequest = {
    uuid: generateUuidV4(),
    type: "request",
    content: {
      api: "flows",
      resourceName: "flowVersions",
      path: "/v1/flows/versions",
      method: "POST",
      userJWT,
      options: flowsOptions,
      body: newFlowVersion,
    },
  };
  await sendWsRequest<unknown>(wsRequest);
};

export const wsDeleteFlowVersion = async (
  userJWT: string,
  flowsOptions: GetFlowVersionOptions
): Promise<void> => {
  const wsRequest: WSRequest = {
    uuid: generateUuidV4(),
    type: "request",
    content: {
      api: "flows",
      resourceName: "flowVersions",
      path: "/v1/flows/versions",
      method: "DELETE",
      userJWT,
      options: flowsOptions,
    },
  };
  await sendWsRequest<unknown>(wsRequest);
};

export const wsPromoteFlowVersion = async (
  userJWT: string,
  flowsOptions: GetFlowVersionOptions
): Promise<FlowVersion> => {
  const wsRequest: WSRequest = {
    uuid: generateUuidV4(),
    type: "request",
    content: {
      api: "flows",
      resourceName: "flowVersions",
      path: "/v1/flows/versions/promote",
      method: "POST",
      userJWT,
      options: flowsOptions,
    },
  };
  const result = await sendWsRequest<FlowVersion>(wsRequest);
  return result.body;
};

// Flow version config

export const wsGetFlowVersionConfig = (
  userJWT: string,
  options: GetFlowVersionOptions
) => {
  const wsRequest: WSRequest = {
    uuid: generateUuidV4(),
    type: "request",
    content: {
      api: "flows",
      resourceName: "flowVersions",
      path: "/v1/flows/versions/config",
      method: "GET",
      userJWT,
      options,
    },
  };
  return sendWsRequest<FlowVersionConfig>(wsRequest);
};

export const generateSubscribeToFlowVersionConfig = (
  userJWT: string,
  flowsOptions: GetFlowVersionOptions,
  handlers: EventHandlers
): Subscription => {
  return new Subscription(
    {
      uuid: generateUuidV4(),
      type: "subscription",
      content: {
        action: "subscribe",
        resourceType: "flowVersionConfig",
        resourceUuid: flowsOptions.uuid,
        workspaceUuid: flowsOptions.workspaceUuid,
        userJWT,
      },
    },
    handlers
  );
};

// export const wsUnsubscribeToFlowVersionConfig = (
//   userJWT: string,
//   flowsOptions: GetFlowVersionOptions
// ): void => {
//   const payload: WSSubscription = {
//     uuid: generateUuidV4(),
//     type: "subscription",
//     content: {
//       action: "unsubscribe",
//       resourceType: "flowVersionConfig",
//       resourceUuid: flowsOptions.uuid,
//       resourceParentUuid: flowsOptions.flowUuid,
//       workspaceUuid: flowsOptions.workspaceUuid,
//       userJWT,
//     },
//   };
//   wsInstance.sendMessage(payload);
// };
