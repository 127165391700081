import { ReactElement } from "react";
import classNames from "classnames";
import { useDispatch } from "react-redux";
import HTTPTriggerDetails from "./nodes/httpTrigger/HTTPTriggerDetails";
import HTTPCallDetails from "./nodes/httpCall/HTTPCallDetails";
import { setNodeBeingEdited } from "../../features/flowEditor/flowEditorSlice";
import { useAppSelector } from "../../hooks";

export default function BuilderNodeDetails(): ReactElement {
  const dispatch = useDispatch();
  const nodeBeingEdited = useAppSelector((state) =>
    state.flowEditor.nodes.find(
      (n) => n.uuid === state.flowEditor.nodeBeingEdited
    )
  );

  const classes = classNames({
    "builder-node-details": true,
    "builder-node-details--hidden": !nodeBeingEdited,
  });
  let componentToInclude;
  switch (nodeBeingEdited?.type) {
    case "HTTP_TRIGGER":
      componentToInclude = <HTTPTriggerDetails></HTTPTriggerDetails>;
      break;
    // case "FORMAT":
    //   componentToInclude = <FormatDetails></FormatDetails>;
    //   break;
    // case "BRANCHING":
    //   componentToInclude = <BranchingDetails></BranchingDetails>;
    //   break;
    // case "NOOP":
    //   componentToInclude = <HTTPCallDetails></HTTPCallDetails>;
    //   break;
    case "HTTP_CALL":
      componentToInclude = <HTTPCallDetails></HTTPCallDetails>;
      break;
    default:
      componentToInclude = null;
  }
  return (
    <div className={classes}>
      <div
        className="builder-node-details__popup"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div
          className="builder-node-details__close"
          onClick={() => dispatch(setNodeBeingEdited(null))}
        >
          <i className="ri-close-line"></i>
        </div>
        {componentToInclude}
      </div>
    </div>
  );
}
