import { WSRequest } from ".";
import { generateUuidV4 } from "../helpers/uuid";
import { Node } from "../models/editor/Node";
import { sendWsRequest } from "./wsLikeHttp";

// Not a good idea, too much request
// export const wsSubscribeToNode = async (
//   userJWT: string,
//   nodeUuid: string,
//   workspaceUuid: string,
//   callback: (wsEvent: WSEvent) => void
// ): Promise<void> => {
//   const socket = await wsInstance.getSocket();
//   const payload = {
//     action: `SUBSCRIBE:nodes:${nodeUuid}`,
//     userJWT,
//     content: {
//       options: {
//         nodeUuid,
//       },
//     },
//   };
//   wsInstance.addListener(payload.action, callback);
//   socket?.send(JSON.stringify(payload));
// };

// export const wsUnsubscribefromNode = async (
//   userJWT: string,
//   nodeUuid: string,
//   callback: (wsEvent: WSEvent) => void
// ): Promise<void> => {
//   const socket = await wsInstance.getSocket();
//   const payload = {
//     action: "UNSUBSCRIBE:nodes:all",
//     userJWT,
//     content: {
//       options: {
//         nodeUuid,
//       },
//     },
//   };
//   wsInstance.removeListener("SUBSCRIBE:nodes:all", callback);
//   socket?.send(JSON.stringify(payload));
// };

export interface NodeAndEdgeOptions {
  uuid: string;
  flowVersionUuid: string;
  flowUuid: string;
  workspaceUuid: string;
}

export const upsertNode = (
  userJWT: string,
  options: NodeAndEdgeOptions,
  body: Node
) => {
  const wsRequest: WSRequest = {
    uuid: generateUuidV4(),
    type: "request",
    content: {
      api: "flows",
      resourceName: "flowVersions",
      path: "/v1/flows/versions/nodes",
      method: "PUT",
      userJWT,
      options,
      body,
    },
  };
  return sendWsRequest<unknown>(wsRequest);
};

export const deleteNode = (userJWT: string, options: NodeAndEdgeOptions) => {
  const wsRequest: WSRequest = {
    uuid: generateUuidV4(),
    type: "request",
    content: {
      api: "flows",
      resourceName: "flowVersions",
      path: "/v1/flows/versions/nodes",
      method: "DELETE",
      userJWT,
      options,
    },
  };
  return sendWsRequest<unknown>(wsRequest);
};
