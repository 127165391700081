import { ReactElement } from "react";
import { useAppSelector } from "../../../hooks";

export default function UserSettings(): ReactElement {
  const workspaces = useAppSelector(
    (state) => state.account.workspacesAvailable
  );

  const workspacesTable = (
    <table className="user-settings__workspaces-table">
      <thead>
        <tr>
          <th>Name</th>
          <th>Type</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {workspaces.map((w) => (
          <tr key={w.uuid}>
            <td>{w.name}</td>
            <td>{w.organization ? "Organization" : "Private"}</td>
            <td>
              <button
                disabled={!w.organization}
                className="hktf-button hktf-button-text"
              >
                Leave
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );

  return (
    <div className="user-settings">
      <h1 className="user-settings__title">User settings</h1>
      {/* <h2 className="user-settings__subtitle">Interface</h2>
      <div className="user-settings__section">
        Dark mode <button>Enable</button>
      </div> */}
      <h2 className="user-settings__subtitle">My workspaces</h2>
      <div className="user-settings__section">
        You are currently in the following workspaces.
        {workspacesTable}
      </div>
      {/* <h2 className="user-settings__subtitle">Security</h2>
      <div className="user-settings__section">
        <div></div>
      </div> */}
      <h2 className="user-settings__subtitle">Danger zone</h2>
      <div className="user-settings__section">
        <button className="hktf-button hktf-button-text">Delete account</button>
      </div>
    </div>
  );
}
