import { ReactElement } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import BuilderEditor from "../../../components/builder/BuilderEditor";
import { getEnv, isProduction } from "../../../helpers/isProduction";

export default function FlowBuilder(): ReactElement {
  const docTitle = isProduction() ? "Builder" : `Builder (${getEnv()})`;

  return (
    <HelmetProvider>
      <div className="builder">
        <Helmet>
          <title>{docTitle}</title>
        </Helmet>
        <BuilderEditor></BuilderEditor>
      </div>
    </HelmetProvider>
  );
}
