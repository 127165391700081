import { generateUuidV4 } from "../helpers/uuid";
import { Flow } from "../models/editor/Flow";
import { wsInstance, WSRequest } from "./index";
import { sendWsRequest } from "./wsLikeHttp";

export interface ListFlowsOptions {
  workspaceUuid: string;
}

export interface GetFlowOptions {
  uuid: string;
  workspaceUuid: string;
}

export const wsListFlows = async (
  userJWT: string,
  flowsOptions: ListFlowsOptions
): Promise<Flow[]> => {
  const wsRequest: WSRequest = {
    uuid: generateUuidV4(),
    type: "request",
    content: {
      api: "flows",
      resourceName: "flows",
      path: "/v1/flows",
      method: "LIST",
      userJWT,
      options: flowsOptions,
    },
  };
  const result = await sendWsRequest<Flow[]>(wsRequest);
  return result.body;
};

export const wsGetFlow = async (
  userJWT: string,
  flowsOptions: GetFlowOptions
): Promise<Flow> => {
  const wsRequest: WSRequest = {
    uuid: generateUuidV4(),
    type: "request",
    content: {
      api: "flows",
      resourceName: "flows",
      path: "/v1/flows",
      method: "GET",
      userJWT,
      options: flowsOptions,
    },
  };
  const result = await sendWsRequest<Flow>(wsRequest);
  return result.body;
};

export const wsCreateFlow = async (
  userJWT: string,
  flowsOptions: ListFlowsOptions,
  newFlow: Flow
): Promise<void> => {
  const wsRequest: WSRequest = {
    uuid: generateUuidV4(),
    type: "request",
    content: {
      api: "flows",
      resourceName: "flows",
      path: "/v1/flows",
      method: "POST",
      userJWT,
      options: flowsOptions,
      body: newFlow,
    },
  };
  await sendWsRequest<unknown>(wsRequest);
};

export const wsDeleteFlow = async (
  userJWT: string,
  flowsOptions: GetFlowOptions
): Promise<void> => {
  const wsRequest: WSRequest = {
    uuid: generateUuidV4(),
    type: "request",
    content: {
      api: "flows",
      resourceName: "flows",
      path: "/v1/flows",
      method: "DELETE",
      userJWT,
      options: flowsOptions,
    },
  };
  await sendWsRequest<unknown>(wsRequest);
};
