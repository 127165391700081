import { generateUuidV4 } from "../helpers/uuid";
import { User } from "../models/accounts/User";
import { Workspace } from "../models/accounts/Workspace";
import { WSRequest } from "./index";
import { sendWsRequest } from "./wsLikeHttp";

export const wsGetMyInfos = async (userJWT: string): Promise<User> => {
  const wsRequest: WSRequest = {
    uuid: generateUuidV4(),
    type: "request",
    content: {
      api: "accounts",
      resourceName: "me",
      path: "/v1/me/infos",
      method: "GET",
      userJWT,
      body: {},
    },
  };

  const result = await sendWsRequest<User>(wsRequest);
  return result.body;
};

export const wsGetMyWorkspaces = async (
  userJWT: string
): Promise<Workspace[]> => {
  const wsRequest: WSRequest = {
    uuid: generateUuidV4(),
    type: "request",
    content: {
      api: "accounts",
      resourceName: "me",
      path: "/v1/me/workspaces",
      method: "GET",
      userJWT,
      body: {},
    },
  };

  const result = await sendWsRequest<Workspace[]>(wsRequest);
  return result.body;
};

export const wsGetUsersOfWorkspace = async (
  userJWT: string,
  workspaceUuid: string
): Promise<User[]> => {
  const wsRequest: WSRequest = {
    uuid: generateUuidV4(),
    type: "request",
    content: {
      api: "accounts",
      resourceName: "workspaces",
      path: "/v1/workspaces/users",
      method: "LIST",
      userJWT,
      options: {
        workspaceUuid,
      },
      body: {},
    },
  };

  const result = await sendWsRequest<User[]>(wsRequest);
  return result.body;
};

export const wsCreateWorkspace = async (
  userJWT: string,
  newWorkspace: Workspace
): Promise<void> => {
  const wsRequest: WSRequest = {
    uuid: generateUuidV4(),
    type: "request",
    content: {
      api: "accounts",
      resourceName: "workspaces",
      path: "/v1/workspaces",
      method: "POST",
      userJWT,
      body: newWorkspace,
    },
  };
  const result = await sendWsRequest<void>(wsRequest);
  return result.body;
};
