import { Dispatch } from "react";
import { upsertSampleNode } from "../features/flowEditor/flowEditorSlice";
import { setHTTPTriggerPayload } from "../features/flowEditor/httpTriggerSlice";
import { SampleNode } from "../models/editor/SampleNode";
import { WSEvent } from "../_ws_";

// export const flowVersionConfigHandlers: EventHandlers = {
//   HTTP_TRIGGER_INPUT: (event: WSEvent) => {},
// };

export const generateFlowVersionConfigHandlers = (dispatch: Dispatch<any>) => ({
  HTTP_TRIGGER_INPUT: (event: WSEvent) => {
    window.debug && console.log("EVENT RECEIVED FOR HTTP_TRIGGER_INPUT", event);
    dispatch(setHTTPTriggerPayload(event));
    // dispatch();
    // updateNodeInput({ uuid: event.resourceUuid, content: event.body })
  },
  SAMPLE_NODE_UPSERT: (event: WSEvent) => {
    window.debug && console.log("EVENT RECEIVED FOR SAMPLE_NODE_UPSERT", event);
    const sampleNode = event.body as SampleNode;
    dispatch(upsertSampleNode(sampleNode));
  },
});
