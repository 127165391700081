import { ChangeEvent, ReactElement, useEffect, useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useHistory } from "react-router-dom";
import { getEnv, isProduction } from "../../../helpers/isProduction";
import { generateUuidV4, shortenUuid } from "../../../helpers/uuid";
import { useAppSelector } from "../../../hooks";
import { Flow } from "../../../models/editor/Flow";
import { wsInstance, WSResponse } from "../../../_ws_";
import { wsCreateFlow } from "../../../_ws_/flowsService";

export default function FlowCreate(): ReactElement {
  const jwt = useAppSelector((state) => state.account.jwt);
  const currentWorkspace = useAppSelector(
    (state) => state.account.currentWorkspace
  );
  const history = useHistory();

  const [flow, setFlow] = useState({
    uuid: generateUuidV4(),
    name: "",
    state: "ENABLED",
    workspaceUuid: currentWorkspace?.uuid,
  } as Flow);

  // const [requestSent, setRequestSent] = useState(false);

  // useEffect(() => {
  //   const createFlowActionName = "/v1/flows";
  //   const createFlowResponseHandler = (wsMessage: WSResponse) => {
  //     if (wsMessage.statusCode === 200) {
  //       history.replace(`/flows/v/${shortenUuid(flow.uuid)}`);
  //     } else {
  //       console.error("Create flow - error received", wsMessage);
  //     }
  //     wsInstance.removeListener(
  //       createFlowActionName,
  //       createFlowResponseHandler
  //     );
  //     setRequestSent(false);
  //   };
  //   wsInstance.on(createFlowActionName, createFlowResponseHandler);

  //   return () => {
  //     wsInstance.removeListener(
  //       createFlowActionName,
  //       createFlowResponseHandler
  //     );
  //   };
  // }, [requestSent, flow.uuid, history]);

  if (!jwt || !currentWorkspace) {
    return <div></div>;
  }

  const onNameChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setFlow({
      ...flow,
      name: event.target.value,
    });
  };

  const onClickCreate = async (): Promise<void> => {
    if (!jwt || !currentWorkspace) {
      return;
    }
    // setRequestSent(true);
    try {
      await wsCreateFlow(jwt, { workspaceUuid: currentWorkspace.uuid }, flow);
      history.replace(`/flows/v/${shortenUuid(flow.uuid)}`);
    } catch (error) {
      window.debug && console.error("Create flow - error received", error);
    }
  };

  const form = (
    <div className="flow-create__form">
      <span className="input-text__caption">Name</span>
      <input
        className="input-text__input"
        required
        name="name"
        type="text"
        id="name"
        value={flow.name}
        onChange={onNameChange}
      />
    </div>
  );

  const buttons = (
    <div className="flow-create__buttons">
      <button className="hktf-button hktf-button-text" onClick={history.goBack}>
        Cancel
      </button>
      <button
        className="hktf-button hktf-button-primary"
        onClick={onClickCreate}
      >
        Continue
      </button>
    </div>
  );

  const content = (
    <>
      <h2 className="flow-create__title">New flow</h2>
      {form}
      {buttons}
    </>
  );

  const docTitle = isProduction()
    ? "Creating flow"
    : `Creating flow (${getEnv()})`;

  return (
    <HelmetProvider>
      <div className="flow-create container">
        <Helmet>
          <title>{docTitle}</title>
        </Helmet>
        {content}
      </div>
    </HelmetProvider>
  );
}
