import React, { ReactElement } from "react";
import { useHistory } from "react-router";
import { shortenUuid } from "../../../helpers/uuid";
import { FlowVersion } from "../../../models/editor/FlowVersion";

export default function FlowVersionsList({
  flowVersions,
  deleteFlowVersionCallback,
}: {
  flowVersions: FlowVersion[];
  deleteFlowVersionCallback: (uuid: string) => void;
}): ReactElement {
  const { push } = useHistory();

  const content = flowVersions.map((row) => (
    <tr key={row.uuid}>
      <td>{row.name}</td>
      <td>{row.state}</td>
      <td>
        <button
          className="hktf-button hktf-button-primary"
          onClick={() =>
            push(
              `/flows/${shortenUuid(row.flowUuid)}/versions/${shortenUuid(
                row.uuid
              )}/b`
            )
          }
        >
          {row.state === "DRAFT" ? "Edit" : "View"}
        </button>
      </td>
      <td>
        <button
          className="hktf-button hktf-button-text"
          onClick={() => deleteFlowVersionCallback(row.uuid)}
          disabled={row.state !== "DRAFT"}
        >
          Delete
        </button>
      </td>
    </tr>
  ));

  return (
    <React.Fragment>
      <h3 className="flow-details__subtitle">Versions</h3>
      <table className="flow-details__flows-versions-table">
        <thead>
          <tr>
            <th>Name</th>
            <th>State</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>{content}</tbody>
      </table>
    </React.Fragment>
  );
}
