import { Monaco } from "@monaco-editor/react";

export function addMonacoThemes(monaco: Monaco) {
  // monaco.editor.defineTheme("readOnly", {
  //   base: "vs",
  //   inherit: true,
  //   rules: [],
  //   colors: {
  //     "editor.background": "#EEEEEE",
  //   },
  // });

  // Define a new theme that contains only rules that match this language
  monaco.editor.defineTheme("jsonataTheme", {
    base: "vs",
    inherit: true,
    rules: [
      { token: "jsonata-string", foreground: "a00000" },
      { token: "jsonata-comment", foreground: "008000" },
      { token: "jsonata-variable", foreground: "ff4000" },
      { token: "jsonata-names", foreground: "0000c0" },
    ],
    colors: {
      "editor.background": "#fffffb",
    },
  });
}
