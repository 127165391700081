import classNames from "classnames";
import { ReactElement } from "react";
import { NodeType } from "../../../../models/editor/Node";

const typeToIconMap = {
  [NodeType.HTTP_TRIGGER]: <i className="ri-download-cloud-fill"></i>,
  [NodeType.HTTP_CALL]: <i className="ri-upload-cloud-fill"></i>,
};

const typeToClassMap = {
  [NodeType.HTTP_TRIGGER]: "node-icon__http-trigger",
  [NodeType.HTTP_CALL]: "node-icon__http-call",
};

export function NodeIcon({
  nodeType,
  withoutBg,
}: {
  nodeType: NodeType;
  withoutBg?: boolean;
}): ReactElement {
  const mainSpanClasses = classNames({
    "node-icon": true,
    "node-icon--without-bg": withoutBg === true,
    [typeToClassMap[nodeType]]: true,
  });

  return <span className={mainSpanClasses}>{typeToIconMap[nodeType]}</span>;
}
