import { ReactElement, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import localforage from "localforage";
import LogoWithText from "../../components/logo/LogoWithText";
import { setJWT } from "../../features/account/accountSlice";
import { useAppDispatch } from "../../hooks";
import * as ws from "../../_ws_/loginService";
import { WSResponse, ResponseError } from "../../_ws_/index";
import { BoxError } from "../../components/common/messageBoxes/BoxError";
import { Checkbox } from "../../components/common/inputs/Checkbox";
import { JWT_ACCESS_KEY } from "../login/Login";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { getEnv, isProduction } from "../../helpers/isProduction";

declare global {
  interface Window {
    google: any;
  }
}

interface GoogleResponse {
  credential: string;
}

window.google = window.google || {};

export default function Register(): ReactElement {
  const dispatch = useAppDispatch();
  const history = useHistory();

  // State
  const [termsChecked, setTermsChecked] = useState(false);
  const [step, setStep] = useState(0);
  const [errorCode, setErrorCode] = useState("");

  // Check local storage
  useEffect(() => {
    const checkJwtLocalStorage = async () => {
      const jwtFromLocalStorage = await localforage.getItem<string>(
        JWT_ACCESS_KEY
      );
      if (jwtFromLocalStorage) {
        history.replace("/login");
      }
    };

    checkJwtLocalStorage();
  }, [history]);

  useEffect(() => {
    const handleResponseGoogle = async (response: GoogleResponse) => {
      try {
        const registerResponse = await ws.wsRegister(response.credential);
        const content = registerResponse.body;
        if (content.userJWT) {
          dispatch(setJWT(content.userJWT));
        }
        history.push("/");
      } catch (errorRaw) {
        const error = errorRaw as WSResponse<ResponseError>;
        const errorBody = error.body;
        if (errorBody && errorBody.errorCode) {
          setErrorCode(errorBody.errorCode);
        }
      }
    };

    const initializeGoogle = () => {
      if (window.google && window.google.accounts) {
        window.google.accounts.id.initialize({
          client_id:
            "864084918302-o80612s9oabf9rv9ess9mlq6hu3igrjr.apps.googleusercontent.com",
          callback: handleResponseGoogle,
          ux_mode: "popup",
          prompt_parent_id: "google-prompt",
          context: "signin",
          auto_select: true,
        });
        window.google.accounts.id.renderButton(
          document.getElementById("google-button"),
          {
            theme: "filled",
            size: "large",
            text: "continue_with",
          }
        );
      } else {
        window.debug && console.error("Google not loaded");
      }
    };

    const loadGoogle = () => {
      if (!document.getElementById("google-accounts-script")) {
        // Add script
        const script = document.createElement("script");
        script.id = "google-accounts-script";
        script.src = "https://accounts.google.com/gsi/client";
        script.async = true;
        script.onload = initializeGoogle;
        document.body.appendChild(script);
      } else {
        initializeGoogle();
      }
    };
    if (step !== 0) {
      loadGoogle();
    }
  }, [step, dispatch, history]);

  const common = (
    <>
      <div className="register-modal__divider"></div>
      <div className="register-modal__login-text">
        Already have an account ?{" "}
        <Link to="/login" className="register-modal__login-link">
          Sign in
        </Link>
      </div>
    </>
  );

  let error = <></>;
  switch (errorCode) {
    case "":
      break;
    case "ACCOUNT_ALREADY_EXISTS":
      error = <BoxError text="This account already exists."></BoxError>;
      break;
    case "REGISTRATIONS_CLOSED":
      error = <BoxError text="Registrations are closed."></BoxError>;
      break;
    default:
      error = <BoxError text="An unknown error happened."></BoxError>;
      break;
  }

  const content =
    step === 1 ? (
      <div className="register-modal__content">
        {/* <input
          type="email"
          className="register-modal__input"
          placeholder="Email address"
        />
        <input
          type="submit"
          className="register-modal__button"
          disabled
          value="Registrations closed"
        />
        <div className="register-modal__divider-with-text">OR</div> */}
        <div id="google-button"></div>
        <div id="google-prompt"></div>

        {error}

        {common}
      </div>
    ) : (
      <div className="register-modal__content">
        <div className="register-modal__warning-text">
          This service is in <span className="hktf-text-bold">alpha</span>,
          which means
          <ul className="register-modal__warning-list">
            <li className="register-modal__warning-list-item">
              - At any point of time, the service may become unavailable.
            </li>
            <li className="register-modal__warning-list-item">
              - At any point of time, data may be erased.
            </li>
            <li className="register-modal__warning-list-item">
              -{" "}
              <span className="hktf-text-bold">
                Errors are likely to happen
              </span>
              .
            </li>
          </ul>
          <span className="register-modal__warning-checkbox">
            <span>Do you agree with these terms ?</span>
            <Checkbox
              onClick={() => setTermsChecked(!termsChecked)}
              defaultValue={false}
            ></Checkbox>
          </span>
          {/* <input
            type="checkbox"
            onClick={() => setTermsChecked(!termsChecked)}
          ></input> */}
          <button
            className="register-modal__button"
            onClick={() => termsChecked && setStep(1)}
          >
            Continue
          </button>
        </div>
        {common}
      </div>
    );

  const docTitle = isProduction() ? "Register" : `Register (${getEnv()})`;

  return (
    <HelmetProvider>
      <div>
        <Helmet>
          <title>{docTitle}</title>
        </Helmet>
        <div className="register-page">
          <div className="register-modal">
            <header className="register-modal__header">
              <LogoWithText></LogoWithText>
              <h1 className="register-modal__title">Sign up</h1>
            </header>
            {content}
          </div>
        </div>
      </div>
    </HelmetProvider>
  );
}
