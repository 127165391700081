import { ReactElement } from "react";
import { useAppSelector } from "../../hooks";

export function PopupConnectionLost(): ReactElement {
  const connectionStatus = useAppSelector(
    (state) => state.account.connectionStatus
  );

  const generateBaseDom = (inside: ReactElement) => (
    <div className="popup-connection-status">
      <div className="popup-connection-status__popup">{inside}</div>
    </div>
  );

  let connectionStatusDom;
  switch (connectionStatus) {
    case "OFFLINE": {
      const dom = (
        <div className="popup-connection-status__content">
          <span>Reconnecting</span>
          <div className="hktf-loader">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      );
      connectionStatusDom = generateBaseDom(dom);
      break;
    }
    default: {
      connectionStatusDom = <></>;
    }
  }

  return connectionStatusDom;
}
