import React from "react";

import { ReactComponent as LogoSVG } from "./../../assets/logo/Logo.svg";

interface MyProps {}

interface MyState {}

export default class Logo extends React.Component<MyProps, MyState> {
  state = {};

  render() {
    return <LogoSVG className="logo"></LogoSVG>;
  }
}
