import { ChangeEvent, MouseEvent, ReactElement, useState } from "react";

export function Checkbox({
  defaultValue,
  onClick: onClickListener,
  onChange: onChangeListener,
}: {
  defaultValue?: boolean;
  onClick?: (e: MouseEvent) => void;
  onChange?: (e: ChangeEvent) => void;
}): ReactElement {
  const [checked, setChecked] = useState(defaultValue ?? false);

  // useEffect(() => {
  //   if (checkedValueGiven !== undefined && checkedValueGiven !== checked) {
  //     setChecked(checkedValueGiven);
  //   }
  // }, [checkedValueGiven, checked]);

  function onChange(e: ChangeEvent<HTMLInputElement>) {
    window.debug && console.log(`Move from ${checked} to ${e.target.checked}`);
    setChecked(e.target.checked);
    if (onChangeListener) {
      onChangeListener(e);
    }
  }

  return (
    <label className="hktf-checkbox">
      <input
        type="checkbox"
        onClick={onClickListener}
        onChange={onChange}
        checked={checked}
      />
      <span className="hktf-checkbox-checkmark"></span>
    </label>
  );
}
