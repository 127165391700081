import { WSRequest } from ".";
import { generateUuidV4 } from "../helpers/uuid";
import { Sample } from "../models/editor/Sample";
import { SampleNode } from "../models/editor/SampleNode";
import { sendWsRequest } from "./wsLikeHttp";

export interface ListSamplesOptions {
  flowVersionUuid: string;
  flowUuid: string;
  workspaceUuid: string;
}

export interface SingleSampleOptions {
  uuid: string;
  flowVersionUuid: string;
  flowUuid: string;
  workspaceUuid: string;
}

export const listSamplesOfFlowVersion = (
  userJWT: string,
  options: ListSamplesOptions
) => {
  const wsRequest: WSRequest = {
    uuid: generateUuidV4(),
    type: "request",
    content: {
      api: "flows",
      resourceName: "samples",
      path: "/v1/flows/versions/samples",
      method: "LIST",
      userJWT,
      options,
    },
  };
  return sendWsRequest<Sample[]>(wsRequest);
};

export const createSample = (
  userJWT: string,
  options: ListSamplesOptions,
  body: Sample
) => {
  const wsRequest: WSRequest = {
    uuid: generateUuidV4(),
    type: "request",
    content: {
      api: "flows",
      resourceName: "samples",
      path: "/v1/flows/versions/samples",
      method: "POST",
      userJWT,
      options,
      body,
    },
  };
  return sendWsRequest<unknown>(wsRequest);
};

export const updateSample = (
  userJWT: string,
  options: SingleSampleOptions,
  body: Sample
) => {
  const wsRequest: WSRequest = {
    uuid: generateUuidV4(),
    type: "request",
    content: {
      api: "flows",
      resourceName: "samples",
      path: "/v1/flows/versions/samples",
      method: "PUT",
      userJWT,
      options,
      body,
    },
  };
  return sendWsRequest<unknown>(wsRequest);
};

export const deleteSample = (userJWT: string, options: SingleSampleOptions) => {
  const wsRequest: WSRequest = {
    uuid: generateUuidV4(),
    type: "request",
    content: {
      api: "flows",
      resourceName: "samples",
      path: "/v1/flows/versions/samples",
      method: "DELETE",
      userJWT,
      options,
    },
  };
  return sendWsRequest<unknown>(wsRequest);
};

export interface SampleNodeOptions {
  sampleUuid: string;
  flowVersionUuid: string;
  flowUuid: string;
  workspaceUuid: string;
}

export const listSampleNodes = (
  userJWT: string,
  options: SampleNodeOptions
) => {
  const wsRequest: WSRequest = {
    uuid: generateUuidV4(),
    type: "request",
    content: {
      api: "flows",
      resourceName: "samples",
      path: "/v1/flows/versions/sampleNodes",
      method: "LIST",
      userJWT,
      options,
    },
  };
  return sendWsRequest<SampleNode[]>(wsRequest);
};

export const upsertSampleNode = (
  userJWT: string,
  options: SampleNodeOptions,
  body: SampleNode
) => {
  const wsRequest: WSRequest = {
    uuid: generateUuidV4(),
    type: "request",
    content: {
      api: "flows",
      resourceName: "samples",
      path: "/v1/flows/versions/sampleNodes",
      method: "PUT",
      userJWT,
      options,
      body,
    },
  };
  return sendWsRequest<unknown>(wsRequest);
};
