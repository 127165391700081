import { ChangeEvent, ReactElement, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import {
  setCurrentWorkspace,
  setWorkspacesAvailable,
} from "../../../features/account/accountSlice";
import { generateUuidV4 } from "../../../helpers/uuid";
import { useAppSelector } from "../../../hooks";
import { Workspace } from "../../../models/accounts/Workspace";
import { wsCreateWorkspace } from "../../../_ws_/accountsService";

export default function CreateWorkspace(): ReactElement {
  const dispatch = useDispatch();
  const history = useHistory();

  const user = useAppSelector((state) => state.account.user);
  const jwt = useAppSelector((state) => state.account.jwt);
  const workspacesAvailable = useAppSelector(
    (state) => state.account.workspacesAvailable
  );

  const [requestSent, setRequestSent] = useState(false);
  const [newWorkspace, setNewWorkspace] = useState({
    uuid: generateUuidV4(),
    ownerUuid: user?.uuid ?? "",
    organization: true,
    name: "",
    defaultLang: user?.lang ?? "EN",
    main: false,
    state: "ACTIVE",
  } as Workspace);

  if (!jwt) {
    return <div></div>;
  }

  const onChangeName = (event: ChangeEvent<HTMLInputElement>) => {
    setNewWorkspace({
      ...newWorkspace,
      name: event.target.value,
    });
  };

  const onClickCreate = async () => {
    if (!requestSent) {
      try {
        setRequestSent(true);
        await wsCreateWorkspace(jwt, newWorkspace);
        const newWorkspaceAvailable = [
          ...workspacesAvailable.filter((v) => v.uuid !== newWorkspace.uuid),
          newWorkspace,
        ];
        dispatch(setWorkspacesAvailable(newWorkspaceAvailable));
        dispatch(setCurrentWorkspace(newWorkspace.uuid));
        history.replace("/");
      } catch (error) {
        window.debug && console.error("Create flow - error received", error);
        setRequestSent(false);
      }
    }
  };

  return (
    <div className="workspace-create">
      <div className="workspace-create__container">
        <h1 className="workspace-create__title">New workspace</h1>
        <div>
          Name{" "}
          <input
            type="text"
            placeholder="Name"
            value={newWorkspace.name}
            onChange={onChangeName}
          />
          <button onClick={onClickCreate}>Create</button>
        </div>
      </div>
    </div>
  );
}
