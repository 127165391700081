import { ReactElement, useEffect, useState } from "react";
import { useAppSelector } from "../../../hooks";
import { User } from "../../../models/accounts/User";
import { wsGetUsersOfWorkspace } from "../../../_ws_/accountsService";

export default function WorkspaceSettings(): ReactElement {
  const jwt = useAppSelector((state) => state.account.jwt);
  const user = useAppSelector((state) => state.account.user);
  const currentWorkspace = useAppSelector(
    (state) => state.account.currentWorkspace
  );

  const [workspaceUsers, setWorkspaceUsers] = useState([] as User[]);

  useEffect(() => {
    async function initGetWorkspaceUsers() {
      if (!jwt || !currentWorkspace || !user) {
        return;
      }
      try {
        const users = await wsGetUsersOfWorkspace(jwt, currentWorkspace.uuid);
        console.log(users);
        // if (users) {
        // setWorkspaceUsers(users);
        // }
      } catch (error) {
        window.debug &&
          console.error("Get users of workspace - error received", error);
      }
    }
    initGetWorkspaceUsers();
  }, [jwt, user, currentWorkspace]);

  if (!currentWorkspace || !user) {
    return <div></div>;
  }

  const role = user.workspacesRoles
    ? user.workspacesRoles[currentWorkspace.uuid]
    : "";

  const usersTable = (
    <table className="workspace-settings__users-table">
      <thead>
        <tr>
          <th>Email</th>
          <th>Role</th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {workspaceUsers.map((wu) => (
          <tr key={wu.uuid}>
            <td>{wu.email}</td>
            <td>
              {wu.workspacesRoles
                ? wu.workspacesRoles[currentWorkspace.uuid]
                : ""}
            </td>
            <td>
              {role === "ADMIN" && wu.uuid !== user.uuid ? (
                <button>Edit role</button>
              ) : (
                <></>
              )}
            </td>
            <td>
              {role === "ADMIN" && wu.uuid !== user.uuid ? (
                <button>Remove</button>
              ) : (
                <></>
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );

  return (
    <div className="workspace-settings">
      <h1 className="workspace-settings__title">
        Workspace settings ({currentWorkspace.name})
      </h1>

      <h2 className="workspace-settings__subtitle">Users</h2>
      <div className="workspace-settings__section">{usersTable}</div>

      <h2 className="workspace-settings__subtitle">Danger zone</h2>
      <div className="workspace-settings__section">
        <div>
          <button>Leave workspace</button>
        </div>
        <div>
          <button>Delete workspace</button>
        </div>
      </div>
    </div>
  );
}
