export enum SampleState {
  ACTIVE = "ACTIVE",
  DELETED = "DELETED"
}

export interface Sample {
  uuid: string;
  flowVersionUuid: string;
  name: string;
  state: SampleState;
}
