// import classNames from "classnames";
import { ReactElement, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { useAppSelector } from "../../../hooks";
import Logo from "../../logo/Logo";
// import AddButton from "./AddButton";
import NotificationsPopup from "./Notifications";
import UserPopup from "./UserPopup";
import WorkspaceSelector from "./WorkspaceSelector";

export default function TopBar(): ReactElement {
  const builderOpen = useAppSelector(
    (state) => state.flowEditor.flowVersion !== null
  );

  // State
  const [showWorkspaceSelectPopup, setWorkspaceSelectPopup] = useState(false);
  // const [showAddButton, setShowAddButton] = useState(false);
  const [showNotifPopup, setShowNotifPopup] = useState(false);
  const [showUserPopup, setShowUserPopup] = useState(false);

  // function onClickOutsideAkaCloser() {
  //   setWorkspaceSelectPopup(false);
  //   setShowAddButton(false);
  //   setShowNotifPopup(false);
  //   setShowUserPopup(false);
  // }

  // const showOverlayCloser =
  //   showWorkspaceSelectPopup ||
  //   showAddButton ||
  //   showNotifPopup ||
  //   showUserPopup;

  // const closerClassNames = classNames({
  //   "top-bar__submenu-closer": true,
  //   "top-bar__submenu-closer--shown": showOverlayCloser,
  // });

  const firstBar = (
    <div className="top-bar__row top-bar__row--main">
      <div className="top-bar__row_container">
        <div className="top-bar__left">
          <div className="top-bar__logo">
            <Link to="/">
              <Logo></Logo>
            </Link>
          </div>
          <WorkspaceSelector
            showPopup={showWorkspaceSelectPopup}
            setShowPopup={setWorkspaceSelectPopup}
          ></WorkspaceSelector>
        </div>

        <div className="top-bar__middle">
          {/* <div className="top-bar__search-bar">
            <i className="ri-search-line ri-lg"></i>
            <input type="text" placeholder="Search" />
          </div> */}
        </div>
        <div className="top-bar__right">
          <div className="top-bar__menu-item">
            <NotificationsPopup
              showPopup={showNotifPopup}
              setShowPopup={setShowNotifPopup}
            ></NotificationsPopup>
          </div>
          <div className="top-bar__menu-item top-bar__menu-item--last">
            <UserPopup
              showPopup={showUserPopup}
              setShowPopup={setShowUserPopup}
            ></UserPopup>
          </div>
        </div>
      </div>
    </div>
  );

  const secondBar = (
    <div className="top-bar__row top-bar__row--second">
      <div className="top-bar__row_container">
        <div className="top-bar__left">
          <NavLink
            exact
            to="/"
            className="top-bar__menu-item top-bar__menu-item"
            activeClassName="top-bar__menu-item--active"
          >
            Home
          </NavLink>
          <NavLink
            to="/flows"
            className="top-bar__menu-item"
            activeClassName="top-bar__menu-item--active"
          >
            Flows
          </NavLink>
          {/* <NavLink
            to="/reports"
            className="top-bar__menu-item"
            activeClassName="top-bar__menu-item--active"
          >
            Reports
          </NavLink> */}
          <NavLink
            to="/settings"
            className="top-bar__menu-item"
            activeClassName="top-bar__menu-item--active"
          >
            Settings
          </NavLink>
        </div>
        <div className="top-bar__middle"></div>
        <div className="top-bar__right"></div>
      </div>
    </div>
  );

  return (
    <div>
      <header className="top-bar">
        {firstBar}
        {!builderOpen ? secondBar : <></>}
      </header>
      {/* <div className={closerClassNames} onClick={onClickOutsideAkaCloser}></div> */}
    </div>
  );
}
