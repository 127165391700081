import classNames from "classnames";
import { ReactElement, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import {
  setCurrentWorkspace,
  setWorkspacesAvailable,
} from "../../../features/account/accountSlice";
import { useOutsideAlerter } from "../../../helpers/popupRefForClosing";
import { useAppSelector } from "../../../hooks";
// import { Workspace } from "../../../models/accounts/Workspace";
// import { wsInstance, WSMessage } from "../../../_ws_";

const getWorkspaceIcon = (org: boolean) => {
  return org ? (
    <i className="ri-building-line ri-lg"></i>
  ) : (
    <i className="ri-git-repository-private-line ri-lg"></i>
  );
};

// const workspaceCreatedEventName = "event:accounts:workspace:created";
// const workspaceRemovedEventName = "event:accounts:workspace:removed";
// const workspaceDeletedEventName = "event:accounts:workspace:deleted";

export default function WorkspaceSelector({
  showPopup,
  setShowPopup,
}: {
  showPopup: boolean;
  setShowPopup: (v: boolean) => void;
}): ReactElement {
  const dispatch = useDispatch();

  // Global state
  const curWorkspace = useAppSelector(
    (state) => state.account.currentWorkspace
  );
  const workspacesAvail = useAppSelector(
    (state) => state.account.workspacesAvailable
  );

  const history = useHistory();

  // Handle popup
  const popupRef = useRef<HTMLDivElement>(null);
  useOutsideAlerter(popupRef, setShowPopup);

  // useEffect(() => {
  //   const workspaceCreatedHandler = (event: WSMessage) => {
  //     const workspace = event.content as Workspace;
  //     const newWorkspacesAvail = [
  //       ...workspacesAvail.filter((v) => v.uuid !== workspace.uuid),
  //       workspace,
  //     ];
  //     dispatch(setWorkspacesAvailable(newWorkspacesAvail));
  //   };
  //   wsInstance.on(workspaceCreatedEventName, workspaceCreatedHandler);

  //   return () => {
  //     wsInstance.removeListener(
  //       workspaceCreatedEventName,
  //       workspaceCreatedHandler
  //     );
  //   };
  // }, [dispatch, workspacesAvail]);

  if (!curWorkspace) {
    return <div></div>;
  }

  const selectorPopupClassNames = classNames({
    "workspace-selector__popup": true,
    "workspace-selector__popup--show": showPopup,
  });

  function selectWorkspace(uuid: string) {
    window.debug && console.log("Selected ", uuid);
    setShowPopup(false);
    if (curWorkspace && uuid !== curWorkspace.uuid) {
      dispatch(setCurrentWorkspace(uuid));
      history.push("/");
    }
  }

  function moveToCreateWorkspace() {
    setShowPopup(false);
    history.push("/workspaces/n");
  }

  const workspaceSelectorContent = workspacesAvail.map((v) => {
    return (
      <span
        className="workspace-selector__popup-option"
        onClick={() => selectWorkspace(v.uuid)}
        key={v.uuid}
      >
        {getWorkspaceIcon(v.organization)}
        {v.name}
      </span>
    );
  });

  return (
    <div className="workspace-selector">
      <div
        className="workspace-selector__button"
        onClick={() => setShowPopup(!showPopup)}
      >
        <span className="workspace-selector__text">
          {getWorkspaceIcon(curWorkspace.organization)} {curWorkspace.name}
        </span>
        <span className="workspace-selector__icon">
          <i className="ri-arrow-down-s-line"></i>
        </span>
      </div>
      <div ref={popupRef} className={selectorPopupClassNames}>
        {workspaceSelectorContent}
        <span
          className="workspace-selector__popup-option"
          onClick={() => moveToCreateWorkspace()}
        >
          <i className="ri-add-line  ri-lg"></i> New workspace
        </span>
      </div>
    </div>
  );
}
