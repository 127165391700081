import { ReactElement } from "react";

export function ButtonPrimary({
  text,
  onClick,
}: {
  text: string;
  onClick: () => void;
}): ReactElement {
  return (
    <div className="hktf-button hktf-button-primary" onClick={onClick}>
      {text}
    </div>
  );
}
