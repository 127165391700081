import classNames from "classnames";
import { ReactElement, useRef } from "react";
import { useOutsideAlerter } from "../../../helpers/popupRefForClosing";

export default function Notifications({
  showPopup,
  setShowPopup,
}: {
  showPopup: boolean;
  setShowPopup: (v: boolean) => void;
}): ReactElement {
  const popupRef = useRef<HTMLDivElement>(null);

  useOutsideAlerter(popupRef, setShowPopup);

  const notifClassNames = classNames({
    notifications__popup: true,
    "notifications__popup--show": showPopup,
  });

  return (
    <div className="notifications">
      <div
        className="notifications__button"
        onClick={() => setShowPopup(!showPopup)}
      >
        <i className="ri-notification-3-fill ri-lg"></i>
      </div>
      <div ref={popupRef} className={notifClassNames}>
        <h2 className="notifications__popup_title">Notifications</h2>
        <span className="notifications__popup_separator"></span>
        <div className="notifications__popup_empty_state">
          <span className="notifications__popup_empty_state_text">
            No notifications for the moment
          </span>
        </div>
        {/* <div className="notifications__popup-notifications">
          <span>Toto</span>
        </div> */}
      </div>
    </div>
  );
}
