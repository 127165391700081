import { ReactElement } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../../hooks";
import { updateNodeThunk } from "../../../../features/flowEditor/thunks";
import { MonacoJsonataEditor } from "../../../monacoEditor/MonacoJsonataEditor";
import { Node, NodeHTTPCall } from "../../../../models/editor/Node";
import { SampleNodeBuildState } from "../../../../models/editor/SampleNode";
import { InputTextSelf } from "../../../common/inputs/InputTextSelf";
import { onChangeTechnicalNameHandler } from "../../../../applications/services/onChangeTechnicalName";
import { MonacoJsonEditor } from "../../../monacoEditor/MonacoJsonEditor";
import { HTTP_CALL_OPERATION_NAME } from "../../../../helpers/monaco/addJSONSchemaMonaco";
import { NodeHeader } from "../common/NodeHeader";
import { parseJsonata } from "../../../../helpers/parseJsonata";

export default function HTTPCallDetails(): ReactElement {
  const dispatch = useDispatch();

  const jwt = useAppSelector((state) => state.account.jwt);
  const currentWorkspace = useAppSelector(
    (state) => state.account.currentWorkspace
  );
  const flowVersion = useAppSelector((state) => state.flowEditor.flowVersion);
  const node = useAppSelector((state) =>
    state.flowEditor.nodes.find(
      (n) => n.uuid === state.flowEditor.nodeBeingEdited
    )
  ) as NodeHTTPCall;
  let sampleNode = useAppSelector((state) =>
    node ? state.flowEditor.sampleNodes[node.uuid] : undefined
  );
  let sampleUuid = useAppSelector((state) => state.flowEditor.sampleSelected);

  if (!jwt || !currentWorkspace || !flowVersion || !node || !sampleUuid) {
    return <div></div>;
  }

  let configuration = node.configuration as NodeHTTPCall["configuration"];

  configuration = Object.assign(
    {},
    {
      template: "{}",
    },
    configuration
  );

  if (!sampleNode) {
    sampleNode = {
      nodeUuid: node.uuid,
      sampleUuid,
      flowVersionUuid: flowVersion.uuid,
      buildState: SampleNodeBuildState.INITIALIZED,
      input: "",
      output: "",
    };
  }

  let jsonataResult: string;
  try {
    jsonataResult = parseJsonata(sampleNode.input, configuration.template);
  } catch (error) {
    const err = error as Error;
    jsonataResult = err.message;
  }

  // Callbacks
  async function onChangeTemplate(value: string | undefined) {
    if (jwt && currentWorkspace && node && flowVersion && value) {
      const nodeToUpdate: NodeHTTPCall = {
        ...node,
        configuration: {
          ...configuration,
          template: value,
        },
      };
      configuration.template = value;
      await dispatch(
        updateNodeThunk({
          userJWT: jwt,
          options: {
            flowUuid: flowVersion.flowUuid,
            flowVersionUuid: nodeToUpdate.flowVersionUuid,
            uuid: node.uuid,
            workspaceUuid: currentWorkspace.uuid,
          },
          body: nodeToUpdate,
        })
      );
    }
  }

  async function onChangeName(v: string) {
    window.debug && console.log("onChangeName", v);
    if (jwt && currentWorkspace && node && flowVersion) {
      const nodeToUpdate: Node = {
        ...node,
        name: v,
      };
      await dispatch(
        updateNodeThunk({
          userJWT: jwt,
          options: {
            flowUuid: flowVersion.flowUuid,
            flowVersionUuid: nodeToUpdate.flowVersionUuid,
            uuid: node.uuid,
            workspaceUuid: currentWorkspace.uuid,
          },
          body: nodeToUpdate,
        })
      );
    }
  }

  async function onChangeTechnicalName(v: string) {
    window.debug && console.log("onChangeTechnicalName", v);
    // if (jwt && currentWorkspace && node && flowVersion && v) {
    //   await onChangeTechnicalNameHandler(
    //     dispatch,
    //     jwt,
    //     {
    //       flowUuid: flowVersion.flowUuid,
    //       flowVersionUuid: flowVersion.uuid,
    //       uuid: node.uuid,
    //       workspaceUuid: flowVersion.workspaceUuid,
    //     },
    //     node,
    //     v
    //   );
    // }
  }

  let editorsDom;
  if (sampleNode.buildState === SampleNodeBuildState.WAITING_INPUT) {
    editorsDom = (
      <div className="node-waiting">
        <span className="node-waiting__loader">
          <span>Waiting for input</span>
          <div className="hktf-loader">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </span>
      </div>
    );
  } else {
    editorsDom = (
      <div className="builder-node-details__columns">
        <div className="builder-node-details__content">
          <h3 className="builder-node-details__subtitle">Input</h3>
          <MonacoJsonEditor
            size={{ height: "50vh", width: "30vw" }}
            value={sampleNode.input}
            readOnly={true}
          ></MonacoJsonEditor>
        </div>
        <div className="builder-node-details__content">
          <h3 className="builder-node-details__subtitle">Configuration</h3>
          jsonata configuration
          <MonacoJsonataEditor
            value={configuration.template}
            onChangeTemplate={onChangeTemplate}
            readOnly={flowVersion && flowVersion.state === "LIVE"}
            size={{ height: "32vh", width: "30vw" }}
          ></MonacoJsonataEditor>
          jsonata result
          <MonacoJsonEditor
            size={{ height: "32vh", width: "30vw" }}
            value={jsonataResult ? jsonataResult : "Error"}
            readOnly={true}
            path={HTTP_CALL_OPERATION_NAME}
          ></MonacoJsonEditor>
        </div>
        <div className="builder-node-details__content">
          <h3 className="builder-node-details__subtitle">
            Response received (output)
          </h3>
          <MonacoJsonEditor
            size={{ height: "50vh", width: "30vw" }}
            value={sampleNode.output}
            readOnly={true}
          ></MonacoJsonEditor>
        </div>
      </div>
    );
  }

  return (
    <div className="builder-node-details__container">
      <NodeHeader node={node}></NodeHeader>
      <div className="builder-node-details__technical-name">
        Name:{" "}
        <InputTextSelf
          onAccept={onChangeName}
          value={node.name ?? ""}
        ></InputTextSelf>
      </div>
      <div className="builder-node-details__technical-name">
        Unique identifier:{" "}
        <InputTextSelf
          onAccept={onChangeTechnicalName}
          value={node.technicalName ?? ""}
        ></InputTextSelf>
      </div>
      <div className="builder-node-details__content">
        <p>
          The HTTP Call node is an action node. It sends a HTTPs request based
          on the configuration given below.
        </p>
      </div>
      {editorsDom}
    </div>
  );
}
