import { ReactElement } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Login from "./pages/login/Login";
import Register from "./pages/register/Register";
import { Backoffice } from "./Backoffice";
import { getEnv, isProduction } from "./helpers/isProduction";

export default function Main(): ReactElement {
  const docTitle = isProduction() ? "Hooktify" : `Hooktify (${getEnv()})`;

  return (
    <HelmetProvider>
      <Router>
        <Helmet>
          <title>{docTitle}</title>
        </Helmet>
        <Switch>
          <Route exact path="/login" component={Login} />
          <Route exact path="/register" component={Register} />
          <Route path="/" component={Backoffice} />
        </Switch>
      </Router>
    </HelmetProvider>
  );
}
