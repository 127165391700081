import { wsInstance, WSRequest, WSResponse } from ".";
import store from "../store";
import { setJWT } from "../features/account/accountSlice";

let mapOfRequests: { [key: string]: () => void | undefined } = {};

export async function sendWsRequest<T>(
  request: WSRequest
): Promise<WSResponse<T>> {
  window.debug && console.log("request", request);
  const key = request.uuid;
  // if (mapOfRequests[key]) {
  //   mapOfRequests[key]();
  // }

  return new Promise((resolve, reject) => {
    const responseHandler = (response: WSResponse<T>) => {
      clearTimeout(timeout);
      if (response.statusCode >= 200 && response.statusCode < 400) {
        resolve(response);
      } else {
        if (response.statusCode === 401) {
          store.dispatch(setJWT(null));
        }
        reject(response);
      }
    };

    // const cancelHandler = () => {
    //   wsInstance.removeListener(key, responseHandler);
    //   clearTimeout(timeout);
    //   reject(new Error("Canceled"));
    // };

    const timeout = setTimeout(() => {
      wsInstance.removeListener(key, responseHandler);
      reject(new Error("Timeout"));
    }, 1000);

    wsInstance.once(key, responseHandler);
    wsInstance.sendMessage(request);
  });
}
