import classNames from "classnames";
import { SampleNodeBuildState } from "../../models/editor/SampleNode";

export function computeNodeClasses(
  buildState: SampleNodeBuildState,
  isLive = false
): string {
  return classNames({
    "cst-node": true,
    "cst-node-read-only": isLive,
    "cst-node-waiting-input": buildState === SampleNodeBuildState.WAITING_INPUT,
    "cst-node-initialized": buildState === SampleNodeBuildState.INITIALIZED,
    "cst-node-parse-ok": buildState === SampleNodeBuildState.PARSE_OK,
    "cst-node-parse-nok": buildState === SampleNodeBuildState.PARSE_NOK,
    "cst-node-success": buildState === SampleNodeBuildState.SUCCESS,
  });
}
