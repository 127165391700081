import { ReactElement, useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import LogoWithText from "./../../components/logo/LogoWithText";
import { setJWT } from "../../features/account/accountSlice";
import * as ws from "../../_ws_/loginService";
import { WSResponse, ResponseError } from "../../_ws_/index";
import { useAppDispatch, useAppSelector } from "../../hooks";
import localforage from "localforage";
import { BoxError } from "../../components/common/messageBoxes/BoxError";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { getEnv, isProduction } from "../../helpers/isProduction";

declare global {
  interface Window {
    google: any;
    debug?: boolean;
  }
}

window.google = window.google || {};

interface GoogleResponse {
  credential: string;
}

export const JWT_ACCESS_KEY = "userJWT";

export default function SignIn(): ReactElement {
  const query = new URLSearchParams(useLocation().search);
  const history = useHistory();
  const dispatch = useAppDispatch();
  const jwt = useAppSelector((state) => state.account.jwt);

  const [errorCode, setErrorCode] = useState("");

  const redirectToParams = query.get("redirectTo");
  const redirectToUrl = redirectToParams
    ? decodeURIComponent(redirectToParams)
    : "/";

  // Check local storage
  useEffect(() => {
    if (jwt) {
      history.push(redirectToUrl);
      return;
    }

    const checkJwtLocalStorage = async () => {
      const jwtFromLocalStorage = await localforage.getItem<string>(
        JWT_ACCESS_KEY
      );
      if (jwtFromLocalStorage) {
        try {
          await ws.wsCheckJWT(jwtFromLocalStorage);
          dispatch(setJWT(jwtFromLocalStorage));
          history.push(redirectToUrl);
        } catch (error) {
          dispatch(setJWT(null));
          localforage.removeItem(JWT_ACCESS_KEY);
        }
      }
    };

    checkJwtLocalStorage();
  }, [dispatch, history, jwt, redirectToUrl]);

  // Setup Google and handler
  useEffect(() => {
    const handleResponseGoogle = async (response: GoogleResponse) => {
      try {
        const loginResponse = await ws.wsLogin(response.credential);
        const content = loginResponse.body;
        if (content.userJWT) {
          await localforage.setItem(JWT_ACCESS_KEY, content.userJWT);
          dispatch(setJWT(content.userJWT));
        }
        history.push(redirectToUrl);
      } catch (errorRaw) {
        const error = errorRaw as WSResponse<ResponseError>;
        const errorBody = error.body;
        if (errorBody && errorBody.errorCode) {
          setErrorCode(errorBody.errorCode);
        }
      }
    };

    const initializeGoogle = () => {
      if (window.google && window.google.accounts) {
        window.google.accounts.id.initialize({
          client_id:
            "864084918302-o80612s9oabf9rv9ess9mlq6hu3igrjr.apps.googleusercontent.com",
          callback: handleResponseGoogle,
          ux_mode: "popup",
          prompt_parent_id: "google-prompt",
          context: "signin",
          auto_select: true,
        });
        window.google.accounts.id.renderButton(
          document.getElementById("google-button"),
          {
            theme: "filled",
            size: "large",
            text: "continue_with",
          }
        );
      } else {
        window.debug && console.error("Google not loaded");
      }
    };
    const loadGoogle = () => {
      if (!document.getElementById("google-accounts-script")) {
        // Add script
        const script = document.createElement("script");
        script.id = "google-accounts-script";
        script.src = "https://accounts.google.com/gsi/client";
        script.async = true;
        script.onload = initializeGoogle;
        document.body.appendChild(script);
      } else {
        initializeGoogle();
      }
    };

    loadGoogle();
  }, [dispatch, history, jwt, redirectToUrl]);

  let error = <></>;
  switch (errorCode) {
    case "":
      break;
    case "RESOURCE_NOT_FOUND":
      error = <BoxError text="Account not found."></BoxError>;
      break;
    default:
      error = <BoxError text="An unknown error happened."></BoxError>;
      break;
  }

  const docTitle = isProduction() ? "Login" : `Login (${getEnv()})`;

  return (
    <HelmetProvider>
      <div>
        <Helmet>
          <title>{docTitle}</title>
        </Helmet>
        <div className="login-page">
          <div className="login-modal">
            <header className="login-modal__header">
              <LogoWithText></LogoWithText>
              <h1 className="login-modal__title">Sign in</h1>
            </header>
            <div className="login-modal__content">
              <input
                type="email"
                className="login-modal__input"
                placeholder="Email address"
              />
              <input
                type="password"
                className="login-modal__input"
                placeholder="Password"
              />
              <input
                type="submit"
                className="login-modal__button"
                value="Continue"
              />
              <span>OR</span>
              {/* <div className="login-modal__divider-with-text">OR</div> */}
              {/* <div className="login-modal__separator">Separator</div> */}
              {/* <span onClick={this.showButtonSimple}>Click me</span> */}
              <div id="google-button"></div>
              <div id="google-prompt"></div>
              {error}
              <div className="login-modal__divider"></div>
              <div className="login-modal__register-text">
                Don't have an account yet ?{" "}
                <Link to="/register" className="login-modal__register-link">
                  Sign up
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </HelmetProvider>
  );
}
