import classNames from "classnames";
import { ReactElement } from "react";
import { Node, NodeType } from "../../../../models/editor/Node";
import { NodeAdditionalActions } from "./NodeAdditionalActions";
import { NodeIcon } from "./NodeIcon";

const typeToNameMap = {
  [NodeType.HTTP_TRIGGER]: "HTTP Trigger",
  [NodeType.HTTP_CALL]: "HTTP Call",
};

export function NodeHeader({
  node,
  withActions,
}: {
  node: Node;
  withActions?: boolean;
}): ReactElement {
  const headerClasses = classNames({
    "node-header": true,
    [`node-header__${node.type}`]: true,
  });

  const nameDom =
    node.name && node.name !== "" ? (
      <>
        <span className="node-header__type">{typeToNameMap[node.type]}</span>
        <span className="node-header__name">{node.name}</span>
      </>
    ) : (
      <span className="node-header__name">{typeToNameMap[node.type]}</span>
    );

  return (
    <div className={headerClasses}>
      <div className="node-header__left">
        <NodeIcon nodeType={node.type}></NodeIcon>
        <div className="node-header__title-container">{nameDom}</div>
      </div>
      {withActions === true ? (
        <NodeAdditionalActions node={node}></NodeAdditionalActions>
      ) : (
        <></>
      )}
    </div>
  );
}
