import { Action, createSlice } from "@reduxjs/toolkit";
import { format } from "date-fns";
import { User } from "../../models/accounts/User";
import { Workspace } from "../../models/accounts/Workspace";

export interface AccountSliceState {
  lang: string;
  user: User | null;
  workspacesAvailable: Workspace[];
  jwt: string | null;
  currentWorkspace: Workspace | null;
  connectionStatus: string;
  lastDeconnectionTimestamp: string;
}

export const accountSlice = createSlice({
  name: "account",
  initialState: {
    lang: "EN",
    user: null,
    workspacesAvailable: [],
    jwt: null,
    currentWorkspace: null,
    connectionStatus: "DISCONNECTED",
    lastDeconnectionTimestamp: format(
      new Date(),
      "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"
    ),
  } as AccountSliceState,
  reducers: {
    // checkLocalStorage: async (state: AccountSliceState, payload: Action) => {
    //   const [user, workspacesAvailable, jwt] = await Promise.all([
    //     localforage.getItem<User | null>("user"),
    //     localforage.getItem<Workspace[] | null>("workspacesAvailable"),
    //     localforage.getItem<string>("jwt"),
    //   ]);
    //   state.user = user;
    //   state.workspacesAvailable = workspacesAvailable ?? ([] as Workspace[]);
    //   state.jwt = jwt;
    // },
    setUser: (
      state: AccountSliceState,
      action: Action & { payload: User | null }
    ) => {
      state.user = action.payload;
    },
    setWorkspacesAvailable: (
      state: AccountSliceState,
      action: Action & { payload: Workspace[] }
    ) => {
      state.workspacesAvailable = action.payload;
      state.currentWorkspace =
        state.workspacesAvailable.find((v) => v.defaultWorkspace) ||
        state.workspacesAvailable[0];
    },
    setJWT: (
      state: AccountSliceState,
      action: Action & { payload: string | null }
    ) => {
      state.jwt = action.payload;
    },
    setCurrentWorkspace: (
      state: AccountSliceState,
      action: Action & { payload: string }
    ) => {
      state.currentWorkspace =
        state.workspacesAvailable.find((v) => v.uuid === action.payload) ||
        null;
    },
    setConnectionStatus: (
      state: AccountSliceState,
      action: Action & {
        payload: { connectionStatus: string; deconnectionTimestamp: string };
      }
    ) => {
      if (
        action.payload.connectionStatus === "ONLINE" &&
        action.payload.connectionStatus !== state.connectionStatus
      ) {
        state.lastDeconnectionTimestamp = action.payload.deconnectionTimestamp;
      }
      state.connectionStatus = action.payload.connectionStatus;
    },
  },
});

export const {
  setUser,
  setWorkspacesAvailable,
  setJWT,
  setCurrentWorkspace,
  setConnectionStatus,
} = accountSlice.actions;
