import { WSRequest } from ".";
import { generateUuidV4 } from "../helpers/uuid";
import { NodeType } from "../models/editor/Node";
import { sendWsRequest } from "./wsLikeHttp";

export interface ExecuteNodeOptions {
  uuid: string;
  flowVersionUuid: string;
  workspaceUuid: string;
}

export interface ExecuteNodeRequestBody {
  flowVersionUuid: string;
  nodeType: NodeType;
  input: string;
  configuration: unknown;
}

export interface ExecuteNodeResponseBody {
  output: string;
  error?: string;
}

export const executeNode = (
  userJWT: string,
  options: ExecuteNodeOptions,
  body: ExecuteNodeRequestBody
) => {
  const wsRequest: WSRequest = {
    uuid: generateUuidV4(),
    type: "request",
    content: {
      api: "orchestrator",
      resourceName: "execute",
      path: "/v1/execute/node",
      method: "POST",
      userJWT,
      options,
      body,
    },
  };
  return sendWsRequest<ExecuteNodeResponseBody>(wsRequest);
};
