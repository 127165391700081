import { ChangeEvent, ReactElement, useState } from "react";

export function InputTextSelf({
  value,
  onChange: onChangeListener,
  onCancel: onCancelListener,
  onAccept: onAcceptListener,
}: {
  value: string;
  onChange?: (e: ChangeEvent) => void;
  onCancel?: () => void;
  onAccept?: (v: string) => void;
}): ReactElement {
  const [editingValue, setEditingValue] = useState(value);
  const [isEditing, setIsEditing] = useState(false);

  if (!isEditing && editingValue !== value) {
    setEditingValue(value);
  }

  function onClickEdit() {
    setIsEditing(true);
  }

  function onChange(e: ChangeEvent<HTMLInputElement>) {
    setEditingValue(e.target.value);
    if (onChangeListener) {
      onChangeListener(e);
    }
  }

  function onCancel() {
    setEditingValue(value);
    setIsEditing(false);
    if (onCancelListener) {
      onCancelListener();
    }
  }

  function onAccept() {
    setIsEditing(false);
    if (onAcceptListener) {
      onAcceptListener(editingValue);
    }
  }

  const content = isEditing ? (
    <>
      <input
        className="hktf-input-text-self__input"
        type="text"
        value={editingValue}
        onChange={onChange}
      />
      <span className="hktf-input-text-self__icon">
        <i className="ri-close-fill" onClick={onCancel}></i>
      </span>
      <span className="hktf-input-text-self__icon">
        <i className="ri-check-fill" onClick={onAccept}></i>
      </span>
    </>
  ) : (
    <>
      {value ? (
        <span className="hktf-input-text-self__text">{value}</span>
      ) : (
        <></>
      )}
      <span className="hktf-input-text-self__icon">
        <i className="ri-edit-fill" onClick={onClickEdit}></i>
      </span>
    </>
  );

  return <span className="hktf-input-text-self">{content}</span>;
}
