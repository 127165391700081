let storeIsProd: boolean | undefined = undefined;

export function isProduction(): boolean {
  if (storeIsProd === undefined) {
    storeIsProd = process.env.REACT_APP_ENVIRONMENT === "production";
  }
  return storeIsProd;
}

export function getEnv(): string {
  return process.env.REACT_APP_ENVIRONMENT ?? "local";
}
