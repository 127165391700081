import classNames from "classnames";
import localforage from "localforage";
import { ReactElement, useRef } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { setJWT } from "../../../features/account/accountSlice";
import { useOutsideAlerter } from "../../../helpers/popupRefForClosing";

export default function UserPopup({
  showPopup,
  setShowPopup,
}: {
  showPopup: boolean;
  setShowPopup: (v: boolean) => void;
}): ReactElement {
  const dispatch = useDispatch();
  const history = useHistory();

  const popupRef = useRef<HTMLDivElement>(null);

  useOutsideAlerter(popupRef, setShowPopup);

  // const user = useAppSelector((state) => state.account.user);

  const profileClassNames = classNames({
    user_profile__popup: true,
    "user_profile__popup--show": showPopup,
  });

  const onClickUserSettings = () => {
    setShowPopup(false);
    history.push("/me/settings");
  };

  const onClickLogout = () => {
    localforage.removeItem("userJWT");
    setShowPopup(false);
    dispatch(setJWT(null));
    history.push("/login");
  };

  return (
    <div className="user_profile">
      <div
        className="user_profile__button"
        onClick={() => setShowPopup(!showPopup)}
      >
        <i className="ri-user-3-fill ri-lg"></i>
      </div>
      <div ref={popupRef} className={profileClassNames}>
        <ul>
          <li
            className="user_profile__popup-item user_profile__popup-item--link"
            onClick={onClickUserSettings}
          >
            Settings
          </li>
          <li
            className="user_profile__popup-item user_profile__popup-item--link"
            onClick={onClickLogout}
          >
            Sign out
          </li>
        </ul>
      </div>
    </div>
  );
}
