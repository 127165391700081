export enum SampleNodeBuildState {
  WAITING_INPUT = "WAITING_INPUT",
  INITIALIZED = "INITIALIZED",
  PARSE_OK = "PARSE_OK",
  PARSE_NOK = "PARSE_NOK",
  SUCCESS = "SUCCESS",
}

export interface SampleNode {
  sampleUuid: string;
  nodeUuid: string;
  flowVersionUuid: string;
  input: string;
  output: string;
  buildState: SampleNodeBuildState;
}
