import {
  Node as NodeReact,
  Edge as EdgeReact,
  ArrowHeadType,
} from "react-flow-renderer";
import { Node } from "../models/editor/Node";
import { NodeLink } from "../models/editor/NodeLink";

export const convertNodeToNodeReact = (node: Node): NodeReact => ({
  id: node.uuid,
  type: node.type,
  position: {
    x: node.positionX,
    y: node.positionY,
  },
});

export const convertLinkToNodeLink = (link: NodeLink): EdgeReact => ({
  id: link.uuid,
  source: link.nodeFromUuid,
  sourceHandle: link.sourceHandle,
  target: link.nodeToUuid,
  targetHandle: link.targetHandle,
  arrowHeadType: ArrowHeadType.Arrow,
  type: "edge",
});
