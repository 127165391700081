import Branching from "../../components/builder/nodes/branching/Branching";
import Format from "../../components/builder/nodes/format/Format";
import HTTPCall from "../../components/builder/nodes/httpCall/HTTPCall";
import HTTPTrigger from "../../components/builder/nodes/httpTrigger/HTTPTrigger";
import Noop from "../../components/builder/nodes/noop/Noop";

export const nodeTypes = {
  HTTP_TRIGGER: HTTPTrigger,
  FORMAT: Format,
  BRANCHING: Branching,
  HTTP_CALL: HTTPCall,
  NOOP: Noop,
};
