import shortUuid from "short-uuid";

const translator = shortUuid();

export function generateUuidV4(): string {
  return translator.uuid();
}

export function shortenUuid(uuid: string): string {
  return translator.fromUUID(uuid);
}

export function convertToOriginalUuid(shortenedUuid: string): string {
  return translator.toUUID(shortenedUuid);
}
