import jsonata from "jsonata";

export function parseJsonata(input: string, config: string): string {
  let expression;
  let jsonataResult;
  try {
    expression = jsonata(config);
    jsonataResult = expression.evaluate(JSON.parse(input));
    jsonataResult = JSON.stringify(jsonataResult, null, 2);
  } catch (error) {
    throw new Error("Failed to parse Jsonata expression");
  }
  return jsonataResult;
}
