import { WSEvent, wsInstance, WSSubscription } from ".";

export interface EventHandlers {
  [key: string]: (event: WSEvent) => void;
}

export class Subscription {
  private attached = false;

  constructor(
    private payload: WSSubscription,
    private handlers: EventHandlers
  ) {
    window.debug && console.log("RECEIVED", this.handlers);
    this.eventHandler = this.eventHandler.bind(this);
  }

  eventHandler = (event: WSEvent) => {
    window.debug && console.log(this.handlers);
    const handler = this.handlers[event.eventName];
    if (!handler) {
      window.debug && console.error(`No handler for ${event.eventName}`);
      return;
    }
    handler(event);
  };

  generateKey(): string {
    return this.payload.content.resourceParentUuid
      ? `${this.payload.content.resourceType}/${this.payload.content.resourceParentUuid}/${this.payload.content.resourceUuid}`
      : `${this.payload.content.resourceType}/${this.payload.content.resourceUuid}`;
  }

  subscribe = () => {
    if (this.attached) {
      return this;
    }
    this.payload.content.action = "subscribe";
    const key = this.generateKey();
    window.debug && console.log(`[Events] Adding listener with key ${key}`);
    wsInstance.addListener(key, this.eventHandler);
    wsInstance.addListener("close", this.handleOnClose);
    wsInstance.sendMessage(this.payload);
    this.attached = true;
    return this;
  };

  handleOnClose = () => {
    this.attached = false;
    const key = this.generateKey();
    wsInstance.removeListener(key, this.eventHandler);
    wsInstance.removeListener("close", this.handleOnClose);
    wsInstance.addListener("connect", this.handleOnConnect);
  };

  handleOnConnect = () => {
    this.subscribe();
  };

  unsubscribe = () => {
    if (!this.attached) {
      return this;
    }
    this.payload.content.action = "unsubscribe";
    const key = this.generateKey();
    window.debug && console.log(`[Events] Removing listener with key ${key}`);
    wsInstance.sendMessage(this.payload);
    wsInstance.removeListener(key, this.eventHandler);
    wsInstance.removeListener("close", this.handleOnClose);
    return this;
  };
}
