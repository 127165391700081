import { ReactElement } from "react";

export function ModalConfirmation({
  lang,
  onConfirm,
  onCancel,
}: {
  lang: string;
  onConfirm: () => void;
  onCancel: () => void;
}): ReactElement {
  return (
    <div className="hktf-modal hktf-modal-confirm">
      <div className="hktf-modal__flex">
        <div className="hktf-modal__content">
          <p className="hktf-modal__text">
            Do you confirm the deletion of this item ?
          </p>
          <div className="hktf-modal__actions">
            <button onClick={onCancel}>Cancel</button>
            <button onClick={onConfirm}>Delete</button>
          </div>
        </div>
      </div>
      <div className="hktf-modal__bg"></div>
    </div>
  );
}
