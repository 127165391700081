import { Monaco } from "@monaco-editor/react";
import { Uri } from "monaco-editor";
import httpTriggerInputSchema from "../../models/jsonschema/nodes/http_trigger_input.json";
import httpCallOperationSchema from "../../models/jsonschema/nodes/http_call_operation.json";

export const HTTP_TRIGGER_INPUT_NAME = "hooktify://http_trigger/input.json";
export const HTTP_CALL_OPERATION_NAME = "hooktify://http_call/operation.json";

export const JSON_SCHEMA_URI_MAP: { [key: string]: Uri | undefined } = {
  HTTP_TRIGGER_INPUT: undefined,
  HTTP_CALL_OPERATION: undefined,
};

export function addJsonSchemasMonaco(monaco: Monaco) {
  JSON_SCHEMA_URI_MAP.HTTP_TRIGGER_INPUT = monaco.Uri.parse(
    HTTP_TRIGGER_INPUT_NAME
  );

  JSON_SCHEMA_URI_MAP.HTTP_CALL_OPERATION = monaco.Uri.parse(
    HTTP_CALL_OPERATION_NAME
  );

  // configure the JSON language support with schemas and schema associations
  monaco.languages.json.jsonDefaults.setDiagnosticsOptions({
    validate: true,
    schemaValidation: "error",
    schemas: [
      {
        uri: JSON_SCHEMA_URI_MAP.HTTP_TRIGGER_INPUT.toString(),
        fileMatch: [JSON_SCHEMA_URI_MAP.HTTP_TRIGGER_INPUT.toString()],
        schema: httpTriggerInputSchema,
      },
      {
        uri: JSON_SCHEMA_URI_MAP.HTTP_CALL_OPERATION.toString(),
        fileMatch: [JSON_SCHEMA_URI_MAP.HTTP_CALL_OPERATION.toString()],
        schema: httpCallOperationSchema,
      },
    ],
  });
}
