export type Node = NodeHTTPTrigger | NodeHTTPCall;

export enum NodeType {
  HTTP_TRIGGER = "HTTP_TRIGGER",
  HTTP_CALL = "HTTP_CALL",
}

export interface BaseNode {
  uuid: string;
  flowVersionUuid: string;
  state: string;
  name: string;
  technicalName: string;
  positionX: number;
  positionY: number;
}

export interface NodeHTTPTrigger extends BaseNode {
  type: NodeType.HTTP_TRIGGER;
  configuration: {};
}

export interface NodeHTTPCall extends BaseNode {
  type: NodeType.HTTP_CALL;
  configuration: {
    template: string;
  };
}
