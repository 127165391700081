import React, { ReactElement } from "react";
import { FlowVersion } from "../../models/editor/FlowVersion";
import { NodeType } from "../../models/editor/Node";
import { NodeIcon } from "./nodes/common/NodeIcon";

export default function BuilderNodeSelector({
  flowVersion,
}: {
  flowVersion: FlowVersion | null;
}): ReactElement {
  if (!flowVersion || flowVersion.state === "LIVE") {
    return <></>;
  }

  function onDragStart(event: React.DragEvent, nodeType: string) {
    if (event.dataTransfer) {
      event.dataTransfer.setData("application/reactflow", nodeType);
      event.dataTransfer.effectAllowed = "move";
    }
  }

  return (
    <div className="builder-node-selector">
      <div className="builder-node-selector__content">
        {/* <div
          onDragStart={(event) => this.onDragStart(event, "FORMAT")}
          draggable
        >
          <FeatherIcon
            iconName="edit-3"
            additionalClassNames="builder-node-selector__icon"
          ></FeatherIcon>
        </div>
        <div
          onDragStart={(event) => this.onDragStart(event, "BRANCHING")}
          draggable
        >
          <FeatherIcon
            iconName="git-branch"
            additionalClassNames="builder-node-selector__icon"
          ></FeatherIcon>
        </div> */}
        <div
          onDragStart={(event) => onDragStart(event, "HTTP_CALL")}
          draggable
          className="builder-node-selector__icon"
        >
          <NodeIcon nodeType={NodeType.HTTP_CALL} withoutBg={true}></NodeIcon>
        </div>
        {/* <div className="builder-node-selector__separator"></div>
        <div className="builder-node-selector__icon">
          <i className="ri-menu-fold-line"></i>
        </div> */}
        {/* <div
          onDragStart={(event) => this.onDragStart(event, "NOOP")}
          draggable
        >
          <FeatherIcon
            iconName="x-octagon"
            additionalClassNames="builder-node-selector__icon"
          ></FeatherIcon>
        </div> */}
      </div>
    </div>
  );
}
