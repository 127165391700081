import { WSRequest } from ".";
import { generateUuidV4 } from "../helpers/uuid";
import { NodeLink } from "../models/editor/NodeLink";
import { NodeAndEdgeOptions } from "./nodesService";
import { sendWsRequest } from "./wsLikeHttp";

// Not a good idea, too much request
// export const wsSubscribeToNode = async (
//   userJWT: string,
//   nodeUuid: string,
//   workspaceUuid: string,
//   callback: (wsEvent: WSEvent) => void
// ): Promise<void> => {
//   const socket = await wsInstance.getSocket();
//   const payload = {
//     action: `SUBSCRIBE:nodes:${nodeUuid}`,
//     userJWT,
//     content: {
//       options: {
//         nodeUuid,
//       },
//     },
//   };
//   wsInstance.addListener(payload.action, callback);
//   socket?.send(JSON.stringify(payload));
// };

// export const wsUnsubscribefromNode = async (
//   userJWT: string,
//   nodeUuid: string,
//   callback: (wsEvent: WSEvent) => void
// ): Promise<void> => {
//   const socket = await wsInstance.getSocket();
//   const payload = {
//     action: "UNSUBSCRIBE:nodes:all",
//     userJWT,
//     content: {
//       options: {
//         nodeUuid,
//       },
//     },
//   };
//   wsInstance.removeListener("SUBSCRIBE:nodes:all", callback);
//   socket?.send(JSON.stringify(payload));
// };

export const upsertEdge = (
  userJWT: string,
  options: NodeAndEdgeOptions,
  body: NodeLink
) => {
  const wsRequest: WSRequest = {
    uuid: generateUuidV4(),
    type: "request",
    content: {
      api: "flows",
      resourceName: "flowVersions",
      path: "/v1/flows/versions/edges",
      method: "PUT",
      userJWT,
      options,
      body,
    },
  };
  return sendWsRequest<unknown>(wsRequest);
};

export const deleteEdge = (userJWT: string, options: NodeAndEdgeOptions) => {
  const wsRequest: WSRequest = {
    uuid: generateUuidV4(),
    type: "request",
    content: {
      api: "flows",
      resourceName: "flowVersions",
      path: "/v1/flows/versions/edges",
      method: "DELETE",
      userJWT,
      options,
    },
  };
  return sendWsRequest<unknown>(wsRequest);
};
