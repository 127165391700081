import { XYPosition } from "react-flow-renderer";
import { Node, NodeType } from "../models/editor/Node";
import { generateUuidV4 } from "./uuid";
import store from "../store";

function generateTechnicalName(baseName: string) {
  const currentNodes = store.getState().flowEditor.nodes;
  const mapOfNames = currentNodes.reduce((prev, cur) => {
    prev.add(cur.technicalName);
    return prev;
  }, new Set());

  for (let i = 0; i < 1000; i++) {
    let newName = baseName;
    if (i > 0) {
      newName += i;
    }
    if (!mapOfNames.has(newName)) {
      return newName;
    }
  }
  throw new Error(`Cannot find a new name for this node (${baseName})`);
}

export function createNewNode(
  flowVersionUuid: string,
  type: string,
  position: XYPosition
): Node {
  switch (type) {
    case "HTTP_TRIGGER": {
      return {
        uuid: generateUuidV4(),
        type: NodeType.HTTP_TRIGGER,
        positionX: Math.round(position.x),
        positionY: Math.round(position.y),
        flowVersionUuid: flowVersionUuid,
        name: "",
        technicalName: generateTechnicalName("httpTrigger"),
        configuration: {},
        state: "ENABLED",
      };
    }
    case "HTTP_CALL": {
      return {
        uuid: generateUuidV4(),
        type: NodeType.HTTP_CALL,
        positionX: Math.round(position.x),
        positionY: Math.round(position.y),
        flowVersionUuid: flowVersionUuid,
        name: "",
        technicalName: generateTechnicalName("httpCall"),
        configuration: { template: "" },
        state: "ENABLED",
      };
    }
    default:
      throw new Error(`Cannot create new node - ${type} is unknown`);
  }
}
