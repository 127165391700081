import { Dispatch } from "redux";
import { upsertSampleNodeThunk } from "../../features/flowEditor/thunks";
import store from "../../store";
import { SampleNode } from "../../models/editor/SampleNode";
import { SampleNodeOptions } from "../../_ws_/samplesService";

function computeInputReplace(
  technicalName: string,
  currentInput: string,
  newObject: string
): string {
  window.debug && console.log(technicalName, currentInput, newObject);
  let currentInputFixed = currentInput ? currentInput : "{}";
  const currentInputAsObject = JSON.parse(currentInputFixed);
  const newNodeObject = JSON.parse(newObject);
  currentInputAsObject[technicalName] = newNodeObject;
  return JSON.stringify(currentInputAsObject, undefined, 2);
}

export async function onChangeSampleNodeOutputHandler(
  dispatch: Dispatch<any>,
  jwt: string,
  options: SampleNodeOptions,
  nodeSample: SampleNode,
  technicalName: string,
  newObject: string
) {
  const newNodeSample: SampleNode = {
    ...nodeSample,
    output: newObject,
  };
  await dispatch(
    upsertSampleNodeThunk({
      userJWT: jwt,
      options,
      body: newNodeSample,
    })
  );

  // const sampleNodes = store.getState().flowEditor.sampleNodes;
  // const nodeLinks = store.getState().flowEditor.nodeLinks;
  // const promises = [];
  // const sampleNodesAlreadyParsed = new Set();
  // for (const nodeLink of nodeLinks) {
  //   console.log(`${nodeLink.nodeFromUuid} === ${nodeSample.nodeUuid}`);
  //   if (nodeLink.nodeFromUuid === nodeSample.nodeUuid) {
  //     const sampleNodeTo = sampleNodes[nodeLink.nodeToUuid];
  //     if (
  //       sampleNodeTo &&
  //       !sampleNodesAlreadyParsed.has(sampleNodeTo.nodeUuid)
  //     ) {
  //       const sampleNodeToUpdate: SampleNode = {
  //         ...sampleNodeTo,
  //         input: computeInputReplace(
  //           technicalName,
  //           sampleNodeTo.input,
  //           newObject
  //         ),
  //       };
  //       promises.push(
  //         dispatch(
  //           upsertSampleNodeThunk({
  //             userJWT: jwt,
  //             options: {
  //               ...options,
  //               sampleUuid: sampleNodeToUpdate.sampleUuid,
  //             },
  //             body: sampleNodeToUpdate,
  //           })
  //         )
  //       );
  //       sampleNodesAlreadyParsed.add(sampleNodeTo.nodeUuid);
  //     }
  //   }
  // }
  // await Promise.all(promises);
}
