import classNames from "classnames";
import { MouseEvent, ReactElement, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { deleteNodeThunk } from "../../../../features/flowEditor/thunks";
import { useOutsideAlerter } from "../../../../helpers/popupRefForClosing";
import { useAppSelector } from "../../../../hooks";
import { Node, NodeType } from "../../../../models/editor/Node";

export function NodeAdditionalActions({ node }: { node: Node }): ReactElement {
  const dispatch = useDispatch();

  const jwt = useAppSelector((state) => state.account.jwt);
  const currentWorkspace = useAppSelector(
    (state) => state.account.currentWorkspace
  );
  const flowVersion = useAppSelector((state) => state.flowEditor.flowVersion);

  const isSelected = useAppSelector(
    (state) => state.flowEditor.nodeSelected === node.uuid
  );

  const [isVisible, setIsVisible] = useState(false);

  const popupRef = useRef<HTMLDivElement>(null);
  useOutsideAlerter(popupRef, setIsVisible);

  if (!isSelected && isVisible) {
    setIsVisible(false);
  }

  function clickAdditionalActions(event: MouseEvent) {
    window.debug && console.log("Additional actions clicked");
    setIsVisible(!isVisible);
    event.stopPropagation();
    event.preventDefault();
  }

  function onClickDuplicate() {}

  async function onClickDelete() {
    if (jwt && currentWorkspace && flowVersion) {
      await dispatch(
        deleteNodeThunk({
          userJWT: jwt,
          options: {
            flowUuid: flowVersion.flowUuid,
            flowVersionUuid: flowVersion.uuid,
            uuid: node.uuid,
            workspaceUuid: currentWorkspace.uuid,
          },
        })
      );
    }
  }

  const nodeAdditionalActionsClasses = classNames({
    "node-additional-actions": true,
    "node-additional-actions--visible": isVisible,
    // [`node-additional-actions__${type}`]: true,
  });

  const actionsPopup = (
    <div ref={popupRef} className={nodeAdditionalActionsClasses}>
      <span
        className="node-additional-actions__item"
        onClick={onClickDuplicate}
      >
        Duplicate
      </span>
      <span className="node-additional-actions__item" onClick={onClickDelete}>
        Delete
      </span>
    </div>
  );

  const wholeDom =
    node.type !== NodeType.HTTP_TRIGGER ? (
      <>
        <span
          className="node-header__menu-button hktf-tooltip"
          onClick={clickAdditionalActions}
        >
          <i className="ri-more-2-fill"></i>
          <span className="hktf-tooltip__text">Actions</span>
        </span>
        {actionsPopup}
      </>
    ) : (
      <></>
    );

  return wholeDom;
}
