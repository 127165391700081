import classNames from "classnames";
import { ReactElement, useEffect } from "react";
import { Handle, Position } from "react-flow-renderer";
import { useDispatch } from "react-redux";
import {
  upsertSampleNode,
  setNodeBeingEdited,
} from "../../../../features/flowEditor/flowEditorSlice";
import { upsertSampleNodeThunk } from "../../../../features/flowEditor/thunks";
import { computeNodeClasses } from "../../../../helpers/css/computeNodeClasses";
import { useAppSelector } from "../../../../hooks";
import {
  SampleNode,
  SampleNodeBuildState,
} from "../../../../models/editor/SampleNode";
import { executeNode } from "../../../../_ws_/nodeOrchestratorService";
import { NodeHeader } from "../common/NodeHeader";

export default function NodeHttpCall({
  id: uuid,
}: {
  id: string;
}): ReactElement {
  const dispatch = useDispatch();

  const thisNode = useAppSelector((state) =>
    state.flowEditor.nodes.find((v) => v.uuid === uuid)
  );
  const jwt = useAppSelector((state) => state.account.jwt);
  const currentWorkspace = useAppSelector(
    (state) => state.account.currentWorkspace
  );
  const flowVersion = useAppSelector((state) => state.flowEditor.flowVersion);

  let sampleNode = useAppSelector((state) =>
    thisNode ? state.flowEditor.sampleNodes[thisNode.uuid] : undefined
  );
  let sampleUuid = useAppSelector((state) => state.flowEditor.sampleSelected);

  useEffect(() => {
    if (thisNode && sampleUuid && !sampleNode) {
      const defaultSampleNode = {
        nodeUuid: thisNode.uuid,
        sampleUuid,
        flowVersionUuid: thisNode.flowVersionUuid,
        buildState: SampleNodeBuildState.WAITING_INPUT,
        input: "",
        output: "",
      };
      dispatch(upsertSampleNode(defaultSampleNode));
    }
  }, [thisNode, sampleUuid, sampleNode, dispatch]);

  if (
    !thisNode ||
    !sampleUuid ||
    !sampleNode ||
    !jwt ||
    !currentWorkspace ||
    !flowVersion
  ) {
    return <div></div>;
  }

  function onDoubleClickNodeCallback() {
    if (thisNode) {
      dispatch(setNodeBeingEdited(thisNode.uuid));
    }
  }

  async function onClickExecuteNode() {
    if (
      !thisNode ||
      !sampleUuid ||
      !sampleNode ||
      !jwt ||
      !currentWorkspace ||
      !flowVersion
    ) {
      return;
    }
    try {
      const result = await executeNode(
        jwt,
        {
          uuid,
          flowVersionUuid: thisNode.flowVersionUuid,
          workspaceUuid: currentWorkspace.uuid,
        },
        {
          flowVersionUuid: thisNode.flowVersionUuid,
          configuration: thisNode.configuration,
          input: sampleNode.input,
          nodeType: thisNode.type,
        }
      );
      const sampleNodeToUpdate: SampleNode = {
        ...sampleNode,
        output: JSON.stringify(result.body, null, 2),
        buildState: SampleNodeBuildState.SUCCESS,
      };
      await dispatch(
        upsertSampleNodeThunk({
          userJWT: jwt,
          options: {
            sampleUuid: sampleNode.sampleUuid,
            flowVersionUuid: sampleNode.flowVersionUuid,
            flowUuid: flowVersion.flowUuid,
            workspaceUuid: currentWorkspace.uuid,
          },
          body: sampleNodeToUpdate,
        })
      );
    } catch (error) {
      window.debug && console.error("Error while executing node", error);
    }
  }

  const nodeStatusClass = computeNodeClasses(
    sampleNode.buildState,
    flowVersion.state === "LIVE"
  );

  let bottomButtonDom = <></>;
  switch (sampleNode.buildState) {
    case SampleNodeBuildState.WAITING_INPUT: {
      bottomButtonDom = (
        <div className="cst-node__bottom-button">
          <div className="hktf-loader small">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      );
      break;
    }
    case SampleNodeBuildState.INITIALIZED: {
      bottomButtonDom = (
        <div className="cst-node__bottom-button">
          <div className="hktf-loader small">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      );
      break;
    }
    case SampleNodeBuildState.PARSE_NOK: {
      bottomButtonDom = (
        <div className="cst-node__bottom-button" onClick={onClickExecuteNode}>
          Error
        </div>
      );
      break;
    }
    default:
      bottomButtonDom = (
        <div
          className="cst-node__bottom-button cst-node__bottom-button-interactive hktf-tooltip"
          onClick={onClickExecuteNode}
        >
          <i className="ri-play-fill"></i>
          <span className="hktf-tooltip__text">Execute</span>
        </div>
      );
  }

  return (
    <div className={nodeStatusClass} onDoubleClick={onDoubleClickNodeCallback}>
      <Handle
        type="target"
        position={Position.Left}
        style={{ background: "#555" }}
      />
      <div className="cst-node__content">
        <NodeHeader
          node={thisNode}
          withActions={flowVersion && flowVersion.state !== "LIVE"}
        ></NodeHeader>
        <div className="cst-node__body"></div>
      </div>
      {bottomButtonDom}

      <Handle
        type="source"
        position={Position.Right}
        style={{ background: "#555" }}
      />
    </div>
  );
}
