import { Action, createSlice } from "@reduxjs/toolkit";
import { Node, NodeHTTPTrigger } from "../../models/editor/Node";
import { NodeLink } from "../../models/editor/NodeLink";
import {
  FlowVersion,
  FlowVersionConfig,
} from "../../models/editor/FlowVersion";
import { generateBaseFlow } from "../../helpers/baseFlow";
import * as thunks from "./thunks";
import { Sample } from "../../models/editor/Sample";
import { SampleNode } from "../../models/editor/SampleNode";

export interface FlowEditorSliceState {
  loading: boolean;
  saving: boolean;
  flowVersion: FlowVersion | null;
  nodes: Node[];
  nodeLinks: NodeLink[];
  nodeSelected: string | null;
  nodeBeingEdited: string | null;

  samples: Sample[];
  sampleSelected: string | null;
  sampleNodes: { [key: string]: SampleNode };
}

export const flowEditorSlice = createSlice({
  name: "flowEditor",
  initialState: {
    loading: true,
    saving: false,
    flowVersion: null,
    nodes: [],
    nodeLinks: [],
    nodeSelected: null,
    nodeBeingEdited: null,

    samples: [],
    sampleSelected: null,
    sampleNodes: {},
  } as FlowEditorSliceState,
  reducers: {
    resetFlowEditor: (state: FlowEditorSliceState) => {
      state.loading = true;
      state.saving = false;
      state.flowVersion = null;
      state.nodes = [];
      state.nodeLinks = [];
      state.nodeSelected = null;
      state.nodeBeingEdited = null;
    },
    setLoading: (
      state: FlowEditorSliceState,
      action: Action & { payload: boolean }
    ) => {
      state.loading = action.payload;
    },
    setSaving: (
      state: FlowEditorSliceState,
      action: Action & { payload: boolean }
    ) => {
      state.saving = action.payload;
    },
    loadFlowVersionConfig: (
      state: FlowEditorSliceState,
      action: Action & { payload: FlowVersionConfig }
    ) => {
      if (action.payload.nodes.length === 0) {
        state.nodes = generateBaseFlow(action.payload.flowVersion.uuid);
      } else {
        state.nodes = action.payload.nodes;
      }
      state.nodeLinks = action.payload.edges;
      state.flowVersion = action.payload.flowVersion;
      state.nodeSelected = null;
    },
    setFlowVersion: (
      state: FlowEditorSliceState,
      action: Action & { payload: FlowVersion | null }
    ) => {
      state.flowVersion = action.payload;
    },
    // addNode: (
    //   state: FlowEditorSliceState,
    //   action: Action & { payload: Node }
    // ) => {
    //   state.nodes.push(action.payload);
    // },
    updateNodePosition: (
      state: FlowEditorSliceState,
      {
        payload: { uuid, positionX, positionY },
      }: Action & {
        payload: { uuid: string; positionX: number; positionY: number };
      }
    ) => {
      const currentNode = state.nodes.find((v) => v.uuid === uuid);
      if (currentNode) {
        currentNode.positionX = positionX;
        currentNode.positionY = positionY;
      }
    },
    removeNode: (
      state: FlowEditorSliceState,
      action: Action & { payload: string }
    ) => {
      const uuid = action.payload;
      state.nodes = state.nodes.filter((v) => v.uuid !== uuid);
      state.nodeLinks = state.nodeLinks.filter(
        (v) => v.nodeFromUuid !== uuid && v.nodeToUuid !== uuid
      );
      if (state.nodeSelected === uuid) {
        state.nodeSelected = null;
      }
    },
    addLink: (
      state: FlowEditorSliceState,
      action: Action & { payload: NodeLink }
    ) => {
      state.nodeLinks.push(action.payload);
    },
    removeLink: (
      state: FlowEditorSliceState,
      action: Action & { payload: string }
    ) => {
      state.nodeLinks = state.nodeLinks.filter(
        (v) => v.uuid !== action.payload
      );
    },
    setNodeSelected: (
      state: FlowEditorSliceState,
      action: Action & { payload: string | null }
    ) => {
      state.nodeSelected = action.payload;
    },
    setNodeBeingEdited: (
      state: FlowEditorSliceState,
      action: Action & { payload: string | null }
    ) => {
      state.nodeBeingEdited = action.payload;
    },
    // updateNodeBeingEditedConfiguration: (
    //   state: FlowEditorSliceState,
    //   action: Action & { payload: Node["configuration"] }
    // ) => {
    //   if (state.nodeBeingEdited) {
    //     state.nodes = state.nodes.map((n) => {
    //       if (n.uuid === state.nodeBeingEdited) {
    //         switch(n.type) {
    //           case "HTTP_TRIGGER":
    //             return {
    //               ...n,
    //               configuration: action.payload,
    //             };
    //         }
    //         return {
    //           ...n,
    //           configuration: action.payload,
    //         };
    //       }
    //       return n;
    //     });
    //   }
    // },
    updateNodeInput: (
      state: FlowEditorSliceState,
      action: Action & { payload: { uuid: string; content: string } }
    ) => {
      state.nodes = state.nodes.map((n) => {
        if (n.uuid === action.payload.uuid) {
          const node: NodeHTTPTrigger = n as NodeHTTPTrigger;
          return {
            ...node,
            configuration: {
              input: action.payload.content,
            },
          };
        }
        return n;
      });
    },

    setSamples: (
      state: FlowEditorSliceState,
      action: Action & { payload: Sample[] }
    ) => {
      state.samples = action.payload;
      if (state.samples.length > 0) {
        state.sampleSelected = state.samples[0].uuid;
      }
    },
    setSampleSelected: (
      state: FlowEditorSliceState,
      action: Action & { payload: string }
    ) => {
      state.sampleSelected = action.payload;
    },
    upsertSampleNode: (
      state: FlowEditorSliceState,
      action: Action & { payload: SampleNode }
    ) => {
      state.sampleNodes[action.payload.nodeUuid] = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      thunks.loadFlowVersionConfigThunk.fulfilled,
      (state, action) => {
        const payload = action.payload.body;
        if (payload.nodes.length === 0) {
          state.nodes = generateBaseFlow(payload.flowVersion.uuid);
        } else {
          state.nodes = payload.nodes;
        }
        state.nodeLinks = payload.edges;
        state.flowVersion = payload.flowVersion;
        state.nodeSelected = null;
        state.loading = false;
      }
    );
    builder.addCase(thunks.addNodeThunk.fulfilled, (state, action) => {
      state.nodes.push(action.meta.arg.body);
      state.saving = false;
    });
    builder.addCase(thunks.updateNodeThunk.fulfilled, (state, action) => {
      const currentNode = state.nodes.find(
        (v) => v.uuid === action.meta.arg.body.uuid
      );
      if (currentNode) {
        const payload = action.meta.arg.body;
        currentNode.name = payload.name;
        currentNode.technicalName = payload.technicalName;
        currentNode.state = payload.state;
        currentNode.positionX = payload.positionX;
        currentNode.positionY = payload.positionY;
        currentNode.configuration = payload.configuration;
      }
      state.saving = false;
    });
    builder.addCase(thunks.deleteNodeThunk.fulfilled, (state, action) => {
      const uuid = action.meta.arg.options.uuid;
      state.nodes = state.nodes.filter((v) => v.uuid !== uuid);
      state.nodeLinks = state.nodeLinks.filter(
        (v) => v.nodeFromUuid !== uuid && v.nodeToUuid !== uuid
      );
      if (state.nodeSelected === uuid) {
        state.nodeSelected = null;
      }
      state.saving = false;
    });
    builder.addCase(thunks.addEdgeThunk.fulfilled, (state, action) => {
      state.nodeLinks.push(action.meta.arg.body);
      state.saving = false;
    });
    builder.addCase(thunks.deleteEdgeThunk.fulfilled, (state, action) => {
      state.nodeLinks = state.nodeLinks.filter(
        (v) => v.uuid !== action.meta.arg.options.uuid
      );
      state.saving = false;
    });
    builder.addCase(thunks.loadSamplesThunk.fulfilled, (state, action) => {
      state.samples = action.payload.body;
      if (state.samples.length > 0) {
        state.sampleSelected = state.samples[0].uuid;
      }
    });
    builder.addCase(thunks.addNewSampleThunk.fulfilled, (state, action) => {
      state.samples.push(action.meta.arg.body);
      state.saving = false;
    });
    builder.addCase(thunks.updateSampleThunk.fulfilled, (state, action) => {
      const currentSample = state.samples.find(
        (v) => v.uuid === action.meta.arg.body.uuid
      );
      if (currentSample) {
        const payload = action.meta.arg.body;
        currentSample.name = payload.name;
      }
      state.saving = false;
    });
    builder.addCase(thunks.deleteSampleThunk.fulfilled, (state, action) => {
      state.samples = state.samples.filter(
        (v) => v.uuid !== action.meta.arg.options.uuid
      );
      if (state.sampleSelected === action.meta.arg.options.uuid) {
        state.sampleSelected = state.samples[0].uuid;
      }
      state.saving = false;
    });
    builder.addCase(thunks.loadSampleNodes.fulfilled, (state, action) => {
      state.sampleNodes = action.payload.body.reduce((prev, cur) => {
        prev[cur.nodeUuid] = cur;
        return prev;
      }, {} as { [key: string]: SampleNode });
    });
    builder.addCase(thunks.upsertSampleNodeThunk.fulfilled, (state, action) => {
      const sampleNode = state.sampleNodes[action.meta.arg.body.nodeUuid];
      if (sampleNode) {
        const payload = action.meta.arg.body;
        sampleNode.input = payload.input;
        sampleNode.output = payload.output;
        sampleNode.buildState = payload.buildState;
      } else {
        state.sampleNodes[action.meta.arg.body.nodeUuid] = sampleNode;
      }
      state.saving = false;
    });
  },
});

export const {
  resetFlowEditor,
  setSaving,
  setLoading,
  loadFlowVersionConfig,
  setFlowVersion,
  updateNodePosition,
  removeNode,
  addLink,
  removeLink,
  setNodeSelected,
  setNodeBeingEdited,
  // updateNodeBeingEditedConfiguration,
  updateNodeInput,
  setSamples,
  setSampleSelected,
  upsertSampleNode,
} = flowEditorSlice.actions;
