import { ReactElement, useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useAppSelector } from "../../../hooks";
import { Flow } from "../../../models/editor/Flow";
import { FlowVersion } from "../../../models/editor/FlowVersion";
import FlowVersionsList from "./FlowVersionsList";
import { convertToOriginalUuid, generateUuidV4 } from "../../../helpers/uuid";
import { wsGetFlow } from "../../../_ws_/flowsService";
import {
  wsCreateFlowVersion,
  wsDeleteFlowVersion,
  wsListFlowsVersions,
} from "../../../_ws_/flowsVersionsService";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { getEnv, isProduction } from "../../../helpers/isProduction";
import { ModalConfirmation } from "../../../components/common/modals/ModalConfirmation";

export default function FlowDetails(): ReactElement {
  const jwt = useAppSelector((state) => state.account.jwt);
  const currentWorkspace = useAppSelector(
    (state) => state.account.currentWorkspace
  );

  const history = useHistory();

  // State
  const [flow, setFlow] = useState(undefined as Flow | undefined);
  const [flowVersions, setFlowVersions] = useState([] as FlowVersion[]);
  // Delete confirm popup
  const [flowVersionToBeRemoved, setFlowVersionToBeRemoved] = useState("");
  const [showDeletionModal, setShowDeletionModal] = useState(false);

  const flowUuid = convertToOriginalUuid(
    useParams<{ flowUuid: string }>().flowUuid
  );

  // Init, get flow & list flows versions
  useEffect(() => {
    async function retrieveData() {
      if (!jwt || !currentWorkspace) {
        return;
      }
      try {
        const [flow, flowVersions] = await Promise.all([
          wsGetFlow(jwt, {
            workspaceUuid: currentWorkspace.uuid,
            uuid: flowUuid,
          }),
          wsListFlowsVersions(jwt, {
            workspaceUuid: currentWorkspace.uuid,
            flowUuid,
          }),
        ]);
        setFlow(flow);
        setFlowVersions(flowVersions);
      } catch (error) {
        window.debug &&
          console.error("Get flow or list flows - error received", error);
      }
    }
    retrieveData();
  }, [jwt, currentWorkspace, flowUuid]);

  // const getFlowInfos2 = async (
  //   jwt: string,
  //   workspaceUuid: string,
  //   flowUuid: string
  // ) => {
  //   setFlow(await api.getFlow(jwt, workspaceUuid, flowUuid));
  //   setFlowVersions(await api.listFlowVersions(jwt, workspaceUuid, flowUuid));
  // };

  // useEffect(() => {
  //   getFlowInfos2(jwt, currentWorkspace.uuid, flowUuid);
  // }, [jwt, currentWorkspace, flowUuid]);

  if (!jwt || !currentWorkspace) {
    return <div></div>;
  }

  const addVersion = async () => {
    try {
      const newFlowVersion: FlowVersion = {
        uuid: generateUuidV4(),
        workspaceUuid: currentWorkspace.uuid,
        flowUuid,
        name: "New version",
        state: "DRAFT",
      };
      await wsCreateFlowVersion(
        jwt,
        { workspaceUuid: currentWorkspace.uuid, flowUuid },
        newFlowVersion
      );
      const newFlowVersions = [...flowVersions, newFlowVersion];
      setFlowVersions(newFlowVersions);
    } catch (error) {
      window.debug &&
        console.error("Create flow version - error received", error);
    }
  };

  const startProcedureRemoveFlowVersion = (flowVersionUuid: string) => {
    setShowDeletionModal(true);
    setFlowVersionToBeRemoved(flowVersionUuid);
  };

  const deleteFlowVersion = async () => {
    const flowVersionUuid = flowVersionToBeRemoved;
    try {
      await wsDeleteFlowVersion(jwt, {
        workspaceUuid: currentWorkspace.uuid,
        flowUuid,
        uuid: flowVersionUuid,
      });
      const newFlowVersions = flowVersions.filter(
        (v) => v.uuid !== flowVersionUuid
      );
      setFlowVersions(newFlowVersions);
    } catch (error) {
      window.debug &&
        console.error("Delete flow version - error received", error);
    }
    setShowDeletionModal(false);
    setFlowVersionToBeRemoved("");
  };

  if (!flow) {
    return <div></div>;
  }

  const content = (
    <div>
      <h2 className="flow-details__title">{flow.name}</h2>
      <FlowVersionsList
        flowVersions={flowVersions}
        deleteFlowVersionCallback={startProcedureRemoveFlowVersion}
      ></FlowVersionsList>
      <div className="flow-details__buttons">
        <button
          className="hktf-button hktf-button-text"
          onClick={() => history.push("/flows")}
        >
          Back to list
        </button>
        <button
          className="hktf-button hktf-button-primary"
          onClick={() => addVersion()}
        >
          New version
        </button>
      </div>
    </div>
  );

  const docTitle = isProduction()
    ? `${flow.name}`
    : `${flow.name} (${getEnv()})`;

  return (
    <HelmetProvider>
      <div className="flow-details container">
        <Helmet>
          <title>{docTitle}</title>
        </Helmet>
        {content}
      </div>
      {showDeletionModal ? (
        <ModalConfirmation
          onConfirm={() => deleteFlowVersion()}
          onCancel={() => {
            setShowDeletionModal(false);
            setFlowVersionToBeRemoved("");
          }}
          lang="en"
        ></ModalConfirmation>
      ) : (
        <></>
      )}
    </HelmetProvider>
  );
}
