import React from "react";
import { ReactComponent as LogoSVG } from "./../../assets/logo/Logo_with_text.svg";

interface MyProps {}

interface MyState {}

export default class LogoWithText extends React.Component<MyProps, MyState> {
  state = {};

  render() {
    return <LogoSVG className="logo"></LogoSVG>;
  }
}
