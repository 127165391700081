import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { accountSlice } from "./features/account/accountSlice";
import { flowEditorSlice } from "./features/flowEditor/flowEditorSlice";
import { httpTriggerSlice } from "./features/flowEditor/httpTriggerSlice";

const reducers = combineReducers({
  flowEditor: flowEditorSlice.reducer,
  account: accountSlice.reducer,
  httpTrigger: httpTriggerSlice.reducer,
});

const store = configureStore({
  reducer: reducers,
});

export default store;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
