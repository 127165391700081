import Editor, { useMonaco } from "@monaco-editor/react";
import { ReactElement, useEffect } from "react";
import { registerMonacoJsonata } from "../../helpers/monaco/addJsonataMonaco";

export function MonacoJsonataEditor({
  value,
  readOnly,
  onChangeTemplate,
  size,
}: {
  value: string;
  readOnly?: boolean;
  onChangeTemplate?: (value: string | undefined) => void;
  size?: {
    height?: string;
    width?: string;
  };
}): ReactElement {
  // Init monaco
  const monaco = useMonaco();
  useEffect(() => {
    if (monaco) {
      window.debug && console.log("Configure monaco");
      registerMonacoJsonata(monaco);
    }
  }, [monaco]);

  return monaco ? (
    <Editor
      height={size?.height}
      width={size?.width}
      options={{
        minimap: { enabled: false },
        lineNumbers: "off",
        readOnly: readOnly ?? false,
        wordWrap: "on",
        wrappingIndent: "same",
        scrollBeyondLastLine: false,
      }}
      defaultLanguage="jsonata"
      theme="jsonataTheme"
      value={value}
      onChange={onChangeTemplate}
    />
  ) : (
    <div></div>
  );
}
