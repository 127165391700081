import { Monaco } from "@monaco-editor/react";
import { addJsonSchemasMonaco } from "./addJSONSchemaMonaco";
import { addMonacoThemes } from "./addThemesMonaco";

// Taken from
// https://github.com/jsonata-js/jsonata-exerciser/blob/master/src/jsonataMode.js
export function registerMonacoJsonata(monaco: Monaco) {
  const id = "jsonata";

  // Register a new language
  monaco.languages.register({ id });

  // Register a tokens provider for the language
  monaco.languages.setMonarchTokensProvider(id, {
    tokenizer: {
      root: [
        [/\/\*.*\*\//, "jsonata-comment"],
        [/'.*'/, "jsonata-string"],
        [/".*"/, "jsonata-string"],
        [/\$[a-zA-Z0-9_]*/, "jsonata-variable"],
        [/[a-zA-Z0-9_]+/, "jsonata-names"],
      ],
    },
  });

  const brackets = [
    { open: "(", close: ")" },
    { open: "[", close: "]" },
    { open: "{", close: "}" },
    { open: '"', close: '"' },
    { open: "'", close: "'" },
    { open: "`", close: "`" },
  ];
  monaco.languages.setLanguageConfiguration(id, {
    brackets: [
      ["(", ")"],
      ["[", "]"],
      ["{", "}"],
    ],
    autoClosingPairs: brackets,
    surroundingPairs: brackets,
    indentationRules: {
      // ^(.*\*/)?\s*\}.*$
      decreaseIndentPattern: /^((?!.*?\/\*).*\*\/)?\s*[}\])].*$/,
      // ^.*\{[^}"']*$
      increaseIndentPattern:
        /^((?!\/\/).)*(\{[^}"'`]*|\([^)"'`]*|\[[^\]"'`]*)$/,
    },
    // insertSpaces: true,
    // tabSize: 2,
  });

  addMonacoThemes(monaco);
  addJsonSchemasMonaco(monaco);
}
