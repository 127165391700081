import { ReactElement, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useAppSelector } from "../../../hooks";
import { Flow } from "../../../models/editor/Flow";
import { shortenUuid } from "../../../helpers/uuid";
import { wsDeleteFlow, wsListFlows } from "../../../_ws_/flowsService";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { getEnv, isProduction } from "../../../helpers/isProduction";
import { Chip } from "../../../components/common/chips/Chip";
import { ButtonPrimary } from "../../../components/common/buttons/ButtonPrimary";
import { ModalConfirmation } from "../../../components/common/modals/ModalConfirmation";

export default function FlowsList(): ReactElement {
  const jwt = useAppSelector((state) => state.account.jwt);
  const currentWorkspace = useAppSelector(
    (state) => state.account.currentWorkspace
  );

  const [flows, setFlows] = useState([] as Flow[]);
  // Delete confirm popup
  const [flowToBeRemoved, setFlowToBeRemoved] = useState("");
  const [showDeletionModal, setShowDeletionModal] = useState(false);

  const { push } = useHistory();

  useEffect(() => {
    if (!jwt || !currentWorkspace) {
      return;
    }
    const retrieveFlows = async (jwt: string, uuid: string) => {
      try {
        const flows = await wsListFlows(jwt, { workspaceUuid: uuid });
        setFlows(flows);
      } catch (error) {
        window.debug && console.error("List flows - error received", error);
      }
    };
    retrieveFlows(jwt, currentWorkspace.uuid);
  }, [jwt, currentWorkspace]);

  if (!jwt || !currentWorkspace) {
    return <div></div>;
  }

  const startProcedureRemoveFlow = (uuid: string) => {
    setShowDeletionModal(true);
    setFlowToBeRemoved(uuid);
  };

  const removeFlow = async () => {
    const uuid = flowToBeRemoved;
    try {
      await wsDeleteFlow(jwt, { workspaceUuid: currentWorkspace.uuid, uuid });
      setFlows(flows.filter((v) => v.uuid !== uuid));
    } catch (error) {
      window.debug &&
        console.error("List flows - delete - error received", error);
    }
    setShowDeletionModal(false);
    setFlowToBeRemoved("");
  };

  // #######
  // # DOM #
  // #######

  const isLoading = flows === undefined;
  const hasFlows = flows.length ?? -1 > 0;

  const listFlowsDom =
    !isLoading || !hasFlows
      ? flows.map((f) => (
          <tr key={f.uuid}>
            <td>
              <Chip text={f.state.toLowerCase()} state="success"></Chip>
            </td>
            <td>{f.name}</td>
            {/* <td></td>
            <td></td> */}
            <td>
              <ButtonPrimary
                text="Manage"
                onClick={() => push(`/flows/v/${shortenUuid(f.uuid)}`)}
              ></ButtonPrimary>
            </td>
            <td>
              <button
                className="hktf-button hktf-button-text"
                onClick={() => startProcedureRemoveFlow(f.uuid)}
              >
                Delete
              </button>
            </td>
          </tr>
        ))
      : null;

  let content = null;
  if (isLoading) {
    content = <div></div>;
  } else if (!hasFlows) {
    content = (
      <div className="empty">
        <div className="empty-icon">
          <i className="icon icon-people"></i>
        </div>
        <p className="empty-title h5">You have no flows.</p>
        <p className="empty-subtitle">
          Click the button to create a new flow now.
        </p>
        <div className="empty-action">
          <button
            className="hktf-button hktf-button-primary"
            onClick={() => push("/flows/n")}
          >
            Create a new flow
          </button>
        </div>
      </div>
    );
  } else {
    content = (
      <div>
        <table className="flows-list__flows-table">
          <thead>
            <tr>
              <th>Status</th>
              <th>Name</th>
              {/* <th>Last executed at</th>
              <th>Last updated at</th> */}
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>{listFlowsDom}</tbody>
        </table>
        <ButtonPrimary
          text="Create a new flow"
          onClick={() => push("/flows/n")}
        ></ButtonPrimary>
      </div>
    );
  }

  const docTitle = isProduction() ? "Flows" : `Flows (${getEnv()})`;

  return (
    <HelmetProvider>
      <div className="flows-list container">
        <Helmet>
          <title>{docTitle}</title>
        </Helmet>
        <h2 className="flows-list__title">Flows</h2>
        {content}
      </div>
      {showDeletionModal ? (
        <ModalConfirmation
          onConfirm={() => removeFlow()}
          onCancel={() => {
            setShowDeletionModal(false);
            setFlowToBeRemoved("");
          }}
          lang="en"
        ></ModalConfirmation>
      ) : (
        <></>
      )}
    </HelmetProvider>
  );
}
