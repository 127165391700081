import { generateUuidV4 } from "../helpers/uuid";
import { WSRequest, WSResponse } from "./index";
import { sendWsRequest } from "./wsLikeHttp";

export interface WSRegisterResponsePayload {
  userJWT: string;
}

export interface WSLoginResponsePayload extends WSRegisterResponsePayload {}

export const wsRegister = (
  googleJWT: string
): Promise<WSResponse<WSRegisterResponsePayload>> => {
  const wsRequest: WSRequest = {
    uuid: generateUuidV4(),
    type: "request",
    content: {
      api: "accounts",
      resourceName: "security",
      path: "/v1/accounts/register",
      method: "POST",
      body: {
        googleJWT,
      },
    },
  };
  return sendWsRequest<WSLoginResponsePayload>(wsRequest);
};

export const wsLogin = (
  googleJWT: string
): Promise<WSResponse<WSLoginResponsePayload>> => {
  const wsRequest: WSRequest = {
    uuid: generateUuidV4(),
    type: "request",
    content: {
      api: "accounts",
      resourceName: "security",
      path: "/v1/accounts/login",
      method: "POST",
      body: {
        googleJWT,
      },
    },
  };
  return sendWsRequest<WSLoginResponsePayload>(wsRequest);
};

export const wsCheckJWT = (userJWT: string): Promise<unknown> => {
  const payload: WSRequest = {
    uuid: generateUuidV4(),
    type: "request",
    content: {
      api: "accounts",
      resourceName: "security",
      path: "checkJWT",
      method: "POST",
      body: {
        userJWT,
      },
    },
  };
  return sendWsRequest<unknown>(payload);
};
