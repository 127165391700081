import classNames from "classnames";
import { ReactElement, useEffect } from "react";
import { Handle, Position } from "react-flow-renderer";
import { useDispatch } from "react-redux";
import {
  upsertSampleNode,
  setNodeBeingEdited,
} from "../../../../features/flowEditor/flowEditorSlice";
import { computeNodeClasses } from "../../../../helpers/css/computeNodeClasses";
import { useAppSelector } from "../../../../hooks";
import { SampleNodeBuildState } from "../../../../models/editor/SampleNode";
import { NodeHeader } from "../common/NodeHeader";

export default function NodeHttpTrigger({
  id: uuid,
}: {
  id: string;
}): ReactElement {
  const dispatch = useDispatch();
  // const jwt = useAppSelector((state) => state.account.jwt);
  // const currentWorkspace = useAppSelector(
  //   (state) => state.account.currentWorkspace
  // );
  const thisNode = useAppSelector((state) =>
    state.flowEditor.nodes.find((v) => v.uuid === uuid)
  );
  const flowVersion = useAppSelector((state) => state.flowEditor.flowVersion);
  // const isSelected = useAppSelector(
  //   (state) => thisNode && state.flowEditor.nodeSelected === thisNode.uuid
  // );
  let sampleNode = useAppSelector((state) =>
    thisNode ? state.flowEditor.sampleNodes[thisNode.uuid] : undefined
  );
  let sampleUuid = useAppSelector((state) => state.flowEditor.sampleSelected);

  useEffect(() => {
    if (thisNode && sampleUuid && !sampleNode) {
      const defaultSampleNode = {
        nodeUuid: thisNode.uuid,
        sampleUuid,
        flowVersionUuid: thisNode.flowVersionUuid,
        buildState: SampleNodeBuildState.INITIALIZED,
        input: "",
        output: "",
      };
      dispatch(upsertSampleNode(defaultSampleNode));
    }
  }, [thisNode, sampleUuid, sampleNode, dispatch]);

  if (!thisNode || !sampleUuid || !sampleNode || !flowVersion) {
    return <div></div>;
  }

  const nodeStatusClass = computeNodeClasses(
    sampleNode.buildState,
    flowVersion.state === "LIVE"
  );

  // let statusDom: ReactElement;
  // switch (thisNode.buildState) {
  //   case "ERROR": {
  //     statusDom = <span></span>;
  //     break;
  //   }
  //   case "WAITING": {
  //     statusDom = (
  //       <span>
  //         <i className="ri-loader-5-line"></i>
  //       </span>
  //     );
  //     break;
  //   }
  //   case "OK": {
  //     statusDom = <span></span>;
  //     break;
  //   }
  //   case "UNKNOWN":
  //   default: {
  //     statusDom = <span></span>;
  //   }
  // }

  function onDoubleClickNodeCallback() {
    if (thisNode) {
      dispatch(setNodeBeingEdited(thisNode.uuid));
    }
  }

  // function editNodeCallback() {
  //   if (thisNode) {
  //     dispatch(setNodeBeingEdited(thisNode.uuid));
  //   }
  // }

  // function removeNodeCallback() {
  //   if (thisNode && thisNode.type !== NodeType.HTTP_TRIGGER) {
  //     dispatch(removeNode(thisNode.uuid));
  //   }
  // }

  let bottomButtonDom = <></>;
  if (sampleNode.buildState === SampleNodeBuildState.INITIALIZED) {
    bottomButtonDom = (
      <div className="cst-node__bottom-button">
        <div className="hktf-loader small">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    );
  }

  return (
    <div className={nodeStatusClass} onDoubleClick={onDoubleClickNodeCallback}>
      {/* <span className="cst-node__left-bar"></span>
      <span className="cst-node__right-bar"></span> */}
      <div className="cst-node__content">
        <NodeHeader node={thisNode} withActions={true}></NodeHeader>
        <div className="cst-node__body"></div>
        {/* <div className="cst-node__actions">
          <span
            className="cst-node__action-button"
            onClick={() => setShowSubActions(!showSubActions)}
          >
            {!showSubActions ? (
              <i className="ri-split-cells-vertical"></i>
            ) : (
              <i className="ri-merge-cells-vertical"></i>
            )}
          </span>
          <span className="cst-node__action-button" onClick={editNodeCallback}>
            <i className="ri-edit-box-line"></i>
          </span>
          <span className="cst-node__action-button">
            <i className="ri-play-line"></i>
          </span>
        </div>
        {showSubActions ? (
          <div className="cst-node__actions-2">
            <span
              className="cst-node__action-button"
              onClick={removeNodeCallback}
            >
              <i className="ri-delete-bin-2-line"></i>
            </span>
            <span className="cst-node__action-button">
              <i className="ri-file-copy-2-line"></i>
            </span>
            <span className="cst-node__action-button">
              <i className="ri-loader-5-line" style={{ opacity: 0 }}></i>
            </span>
          </div>
        ) : (
          <></>
        )} */}
      </div>
      {bottomButtonDom}
      <Handle
        type="source"
        position={Position.Right}
        style={{ background: "#555" }}
      />
    </div>
  );
}
