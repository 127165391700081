import React, { MouseEvent as ReactMouseEvent, ReactElement } from "react";
import {
  EdgeProps,
  getSmoothStepPath,
  getEdgeCenter,
  getMarkerEnd,
} from "react-flow-renderer";
import { useDispatch } from "react-redux";
import { deleteEdgeThunk } from "../../../features/flowEditor/thunks";
import { useAppSelector } from "../../../hooks";

const foreignObjectSize = 40;

export default function Edge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  arrowHeadType,
  markerEndId,
}: EdgeProps): ReactElement {
  const dispatch = useDispatch();
  const jwt = useAppSelector((state) => state.account.jwt);
  const currentWorkspace = useAppSelector(
    (state) => state.account.currentWorkspace
  );
  const flowVersion = useAppSelector((state) => state.flowEditor.flowVersion);

  const edgePath = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });
  const markerEnd = getMarkerEnd(arrowHeadType, markerEndId);
  const [edgeCenterX, edgeCenterY] = getEdgeCenter({
    sourceX,
    sourceY,
    targetX,
    targetY,
  });

  async function onEdgeClick(event: ReactMouseEvent, id: string) {
    event.stopPropagation();
    if (jwt && currentWorkspace && flowVersion) {
      if (flowVersion.state === "LIVE") {
        throw new Error("Operation not permitted on LIVE flow version");
      }
      await dispatch(
        deleteEdgeThunk({
          userJWT: jwt,
          options: {
            workspaceUuid: currentWorkspace.uuid,
            flowUuid: flowVersion.flowUuid,
            flowVersionUuid: flowVersion.uuid,
            uuid: id,
          },
        })
      );
    }
  }

  const buttonDOM =
    flowVersion && flowVersion.state !== "LIVE" ? (
      <button
        className="edge-button__button"
        onClick={(event) => onEdgeClick(event, id)}
      >
        <i className="ri-close-line"></i>
      </button>
    ) : (
      <></>
    );

  return (
    <>
      <path
        id={id}
        style={style}
        className="react-flow__edge-path edge-button__edge"
        d={edgePath}
        markerEnd={markerEnd}
      />
      <foreignObject
        width={foreignObjectSize}
        height={foreignObjectSize}
        x={edgeCenterX - foreignObjectSize / 2 + 10}
        y={edgeCenterY - foreignObjectSize / 2 + 10}
        className="edge-button__foreign-object"
        requiredExtensions="http://www.w3.org/1999/xhtml"
      >
        {buttonDOM}
      </foreignObject>
    </>
  );
}
