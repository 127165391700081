import classNames from "classnames";
import { ReactElement } from "react";

export function Chip({
  text,
  state,
}: {
  text: string;
  state?: "success" | "warning" | "failure";
}): ReactElement {
  const classes = classNames({
    "hktf-chip": true,
    "hktf-chip-success": state === "success",
    "hktf-chip-warning": state === "warning",
    "hktf-chip-failure": state === "failure",
  });

  return <span className={classes}>{text}</span>;
}
