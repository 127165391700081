import { createAsyncThunk } from "@reduxjs/toolkit";
import * as nodesWS from "../../_ws_/nodesService";
import * as edgesWS from "../../_ws_/edgesService";
import * as samplesWS from "../../_ws_/samplesService";
import { debounce } from "lodash";
import { setLoading, setSaving } from "./flowEditorSlice";
import { Node } from "../../models/editor/Node";
import { NodeLink } from "../../models/editor/NodeLink";
import { Sample } from "../../models/editor/Sample";
import { SampleNode } from "../../models/editor/SampleNode";
import { wsGetFlowVersionConfig } from "../../_ws_/flowsVersionsService";

export const addNodeThunk = createAsyncThunk(
  "nodes/addNode",
  async (
    {
      userJWT,
      options,
      body,
    }: { userJWT: string; options: nodesWS.NodeAndEdgeOptions; body: Node },
    thunkAPI
  ) => {
    thunkAPI.dispatch(setSaving(true));
    return await nodesWS.upsertNode(userJWT, options, body);
  }
);

const debouncedUpdaterNode = debounce(
  (userJWT: string, options: nodesWS.NodeAndEdgeOptions, body: Node) =>
    nodesWS.upsertNode(userJWT, options, body),
  500
);

export const updateNodeThunk = createAsyncThunk(
  "nodes/updateNode",
  async (
    {
      userJWT,
      options,
      body,
    }: { userJWT: string; options: nodesWS.NodeAndEdgeOptions; body: Node },
    thunkAPI
  ) => {
    thunkAPI.dispatch(setSaving(true));
    return debouncedUpdaterNode(userJWT, options, body);
  }
);

export const deleteNodeThunk = createAsyncThunk(
  "nodes/deleteNode",
  async (
    {
      userJWT,
      options,
    }: { userJWT: string; options: nodesWS.NodeAndEdgeOptions },
    thunkAPI
  ) => {
    thunkAPI.dispatch(setSaving(true));
    return await nodesWS.deleteNode(userJWT, options);
  }
);

export const addEdgeThunk = createAsyncThunk(
  "edges/addEdge",
  async (
    {
      userJWT,
      options,
      body,
    }: { userJWT: string; options: nodesWS.NodeAndEdgeOptions; body: NodeLink },
    thunkAPI
  ) => {
    thunkAPI.dispatch(setSaving(true));
    return await edgesWS.upsertEdge(userJWT, options, body);
  }
);

export const deleteEdgeThunk = createAsyncThunk(
  "edges/deleteEdge",
  async (
    {
      userJWT,
      options,
    }: { userJWT: string; options: nodesWS.NodeAndEdgeOptions },
    thunkAPI
  ) => {
    thunkAPI.dispatch(setSaving(true));
    return await edgesWS.deleteEdge(userJWT, options);
  }
);

export const loadFlowVersionConfigThunk = createAsyncThunk(
  "flowVersion/loadVersionConfig",
  async (
    {
      jwt,
      workspaceUuid,
      flowUuid,
      flowVersionUuid,
    }: {
      jwt: string;
      workspaceUuid: string;
      flowUuid: string;
      flowVersionUuid: string;
    },
    thunkApi
  ) => {
    thunkApi.dispatch(setLoading(true));
    return await wsGetFlowVersionConfig(jwt, {
      workspaceUuid,
      flowUuid,
      uuid: flowVersionUuid,
    });
  }
);

export const loadSamplesThunk = createAsyncThunk(
  "samples/listSamples",
  async (
    {
      userJWT,
      options,
    }: { userJWT: string; options: samplesWS.ListSamplesOptions },
    thunkAPI
  ) => {
    const result = await samplesWS.listSamplesOfFlowVersion(userJWT, options);
    if (result.body.length > 0) {
      thunkAPI.dispatch(
        loadSampleNodes({
          userJWT,
          options: {
            ...options,
            sampleUuid: result.body[0].uuid,
          },
        })
      );
    }
    return result;
  }
);

export const addNewSampleThunk = createAsyncThunk(
  "samples/createSample",
  async (
    {
      userJWT,
      options,
      body,
    }: { userJWT: string; options: samplesWS.ListSamplesOptions; body: Sample },
    thunkAPI
  ) => {
    thunkAPI.dispatch(setSaving(true));
    return await samplesWS.createSample(userJWT, options, body);
  }
);

export const updateSampleThunk = createAsyncThunk(
  "samples/updateSample",
  async (
    {
      userJWT,
      options,
      body,
    }: {
      userJWT: string;
      options: samplesWS.SingleSampleOptions;
      body: Sample;
    },
    thunkAPI
  ) => {
    thunkAPI.dispatch(setSaving(true));
    return await samplesWS.updateSample(userJWT, options, body);
  }
);

export const deleteSampleThunk = createAsyncThunk(
  "samples/deleteSample",
  async (
    {
      userJWT,
      options,
    }: { userJWT: string; options: samplesWS.SingleSampleOptions },
    thunkAPI
  ) => {
    thunkAPI.dispatch(setSaving(true));
    return await samplesWS.deleteSample(userJWT, options);
  }
);

export const loadSampleNodes = createAsyncThunk(
  "sampleNodes/listSampleNodes",
  async (
    {
      userJWT,
      options,
    }: { userJWT: string; options: samplesWS.SampleNodeOptions },
    thunkAPI
  ) => {
    return await samplesWS.listSampleNodes(userJWT, options);
  }
);

const debouncedUpsertNodeThunk = debounce(
  (userJWT: string, options: samplesWS.SampleNodeOptions, body: SampleNode) =>
    samplesWS.upsertSampleNode(userJWT, options, body),
  500
);

export const upsertSampleNodeThunk = createAsyncThunk(
  "sampleNodes/upsertSampleNode",
  async (
    {
      userJWT,
      options,
      body,
    }: {
      userJWT: string;
      options: samplesWS.SampleNodeOptions;
      body: SampleNode;
    },
    thunkAPI
  ) => {
    thunkAPI.dispatch(setSaving(true));
    await debouncedUpsertNodeThunk(userJWT, options, body);
  }
);
