import classNames from "classnames";
import { ReactElement, useState } from "react";
import { useDispatch } from "react-redux";
import { setSampleSelected } from "../../features/flowEditor/flowEditorSlice";
import {
  addNewSampleThunk,
  deleteSampleThunk,
  loadSampleNodes,
  updateSampleThunk,
} from "../../features/flowEditor/thunks";
import { generateUuidV4 } from "../../helpers/uuid";
import { useAppSelector } from "../../hooks";
import { FlowVersion } from "../../models/editor/FlowVersion";
import { Sample, SampleState } from "../../models/editor/Sample";

export default function BuilderSamplesSideBar({
  flowVersion,
}: {
  flowVersion: FlowVersion | null;
}): ReactElement {
  const dispatch = useDispatch();

  // Global state account
  const jwt = useAppSelector((state) => state.account.jwt);
  const currentWorkspace = useAppSelector(
    (state) => state.account.currentWorkspace
  );

  const samples = useAppSelector((state) => state.flowEditor.samples);
  const sampleSelected = useAppSelector(
    (state) => state.flowEditor.sampleSelected
  );

  const [isShowingUuid, setIsShowingUuid] = useState("");
  const [isEditingUuid, setIsEditingUuid] = useState("");
  const [newSampleName, setNewSampleName] = useState("");
  const [isAddingNewSample, setIsAddingNewSample] = useState(false);

  if (!jwt || !currentWorkspace || !flowVersion) {
    return <div></div>;
  }

  async function handleOnSelectSample(uuid: string) {
    if (!jwt || !currentWorkspace || !flowVersion) {
      return;
    }
    await dispatch(
      loadSampleNodes({
        userJWT: jwt,
        options: {
          workspaceUuid: currentWorkspace.uuid,
          flowUuid: flowVersion.flowUuid,
          flowVersionUuid: flowVersion.uuid,
          sampleUuid: uuid,
        },
      })
    );
    dispatch(setSampleSelected(uuid));
  }

  async function handleConfirmUpdatedSampleName(s: Sample, newName: string) {
    if (!jwt || !currentWorkspace || !flowVersion) {
      return;
    }
    setIsEditingUuid("");
    setIsShowingUuid("");
    setNewSampleName("");
    if (s.name !== newName) {
      const updatedSample: Sample = {
        ...s,
        name: newName,
      };
      await dispatch(
        updateSampleThunk({
          userJWT: jwt,
          options: {
            workspaceUuid: currentWorkspace?.uuid,
            flowUuid: flowVersion?.flowUuid,
            flowVersionUuid: flowVersion?.uuid,
            uuid: s.uuid,
          },
          body: updatedSample,
        })
      );
    }
  }

  async function handleConfirmNewSample(newName: string) {
    if (!jwt || !currentWorkspace || !flowVersion) {
      return;
    }
    setIsEditingUuid("");
    setIsShowingUuid("");
    setNewSampleName("");
    setIsAddingNewSample(false);
    const newSample: Sample = {
      uuid: generateUuidV4(),
      flowVersionUuid: flowVersion.uuid,
      state: SampleState.ACTIVE,
      name: newName,
    };
    await dispatch(
      addNewSampleThunk({
        userJWT: jwt,
        options: {
          workspaceUuid: currentWorkspace?.uuid,
          flowUuid: flowVersion?.flowUuid,
          flowVersionUuid: flowVersion?.uuid,
        },
        body: newSample,
      })
    );
  }

  function handleCancelNewSample() {
    setIsEditingUuid("");
    setIsShowingUuid("");
    setNewSampleName("");
    setIsAddingNewSample(false);
  }

  async function handleDeleteSample(uuid: string) {
    if (samples.length <= 1) {
      return;
    }
    if (!jwt || !currentWorkspace || !flowVersion) {
      return;
    }
    await dispatch(
      deleteSampleThunk({
        userJWT: jwt,
        options: {
          workspaceUuid: currentWorkspace.uuid,
          flowUuid: flowVersion.flowUuid,
          flowVersionUuid: flowVersion.uuid,
          uuid,
        },
      })
    );
  }

  // DOM

  const samplesDom = samples.map((s) => {
    const classes = classNames({
      "builder-samples-sidebar__button": true,
      "builder-samples-sidebar__button--selected": s.uuid === sampleSelected,
    });

    const isHoveringThisSample = isShowingUuid === s.uuid;

    let itemAdditionalActions;
    if (isHoveringThisSample) {
      if (isEditingUuid === s.uuid) {
        itemAdditionalActions = (
          <>
            <span
              className="builder-samples-sidebar__button builder-samples-sidebar__button--icon"
              onClick={() => handleConfirmUpdatedSampleName(s, newSampleName)}
            >
              <i className="ri-check-fill"></i>
            </span>
          </>
        );
      } else if (isEditingUuid === "") {
        itemAdditionalActions = (
          <>
            <span
              className="builder-samples-sidebar__button builder-samples-sidebar__button--icon"
              onClick={() => {
                setIsEditingUuid(s.uuid);
                setNewSampleName(s.name);
              }}
            >
              <i className="ri-edit-line"></i>
            </span>
            <span
              className="builder-samples-sidebar__button builder-samples-sidebar__button--icon"
              onClick={() => handleDeleteSample(s.uuid)}
            >
              <i className="ri-delete-bin-2-line"></i>
            </span>
          </>
        );
      }
    }

    let nameButton;
    if (isEditingUuid === s.uuid) {
      nameButton = (
        <span className={classes}>
          <input
            type="text"
            value={newSampleName}
            onChange={(e) => setNewSampleName(e.target.value)}
          />
        </span>
      );
    } else if (isHoveringThisSample) {
      nameButton = (
        <span className={classes} onClick={() => handleOnSelectSample(s.uuid)}>
          {s.name}
        </span>
      );
    } else {
      nameButton = (
        <span className={classes} onClick={() => handleOnSelectSample(s.uuid)}>
          {s.name.charAt(0)}
        </span>
      );
    }

    return (
      <span
        className="builder-samples-sidebar__row"
        key={s.uuid}
        onMouseEnter={() =>
          isEditingUuid === "" && !isAddingNewSample
            ? setIsShowingUuid(s.uuid)
            : false
        }
        onMouseLeave={() =>
          isEditingUuid === "" ? setIsShowingUuid("") : false
        }
      >
        {nameButton}
        {itemAdditionalActions}
      </span>
    );
  });

  let addButton;
  if (!isAddingNewSample) {
    addButton = (
      <span
        className="builder-samples-sidebar__button builder-samples-sidebar__button--icon"
        onClick={() => isEditingUuid === "" && setIsAddingNewSample(true)}
      >
        <i className="ri-add-fill"></i>
      </span>
    );
  } else {
    addButton = (
      <>
        <span className="builder-samples-sidebar__button">
          <input
            type="text"
            value={newSampleName}
            onChange={(e) => setNewSampleName(e.target.value)}
          />
        </span>
        <span
          className="builder-samples-sidebar__button builder-samples-sidebar__button--icon"
          onClick={() => handleCancelNewSample()}
        >
          <i className="ri-close-line"></i>
        </span>
        <span
          className="builder-samples-sidebar__button builder-samples-sidebar__button--icon"
          onClick={() => handleConfirmNewSample(newSampleName)}
        >
          <i className="ri-check-fill"></i>
        </span>
      </>
    );
  }

  return (
    <>
      <div className="builder-samples-sidebar">
        {samplesDom}
        <span className="builder-samples-sidebar__row">{addButton}</span>
      </div>
    </>
  );
}
