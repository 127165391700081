import { ChangeEvent, MouseEvent, ReactElement, useState } from "react";

export function RadioButton({
  name,
  value,
  currentValue,
  label,
  // onClick: onClickListener,
  onChange: onChangeListener,
}: {
  name: string;
  value: string;
  currentValue: string;
  label?: string;
  // onClick?: (e: MouseEvent) => void;
  onChange?: (value: string) => void;
}): ReactElement {
  // useEffect(() => {
  //   if (checkedValueGiven !== undefined && checkedValueGiven !== checked) {
  //     setChecked(checkedValueGiven);
  //   }
  // }, [checkedValueGiven, checked]);

  function onChange(e: ChangeEvent<HTMLInputElement>) {
    if (onChangeListener) {
      onChangeListener(e.target.value);
    }
  }

  return (
    <label className="hktf-radio">
      <input
        type="radio"
        name={name}
        value={value}
        onChange={onChange}
        checked={currentValue === value}
      />
      <span className="hktf-radio-fill"></span>
      <span className="hktf-radio-label">{label}</span>
    </label>
  );
}
