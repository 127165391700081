import { ReactElement } from "react";
import { useAppSelector } from "../../../../hooks";
import { generateURLForVersion } from "../../../../api/executeFlowsApi";
import { useDispatch } from "react-redux";
import { updateNodeThunk } from "../../../../features/flowEditor/thunks";
import { Node, NodeHTTPTrigger } from "../../../../models/editor/Node";
import { JSON_SCHEMA_URI_MAP } from "../../../../helpers/monaco/addJSONSchemaMonaco";
import { MonacoJsonEditor } from "../../../monacoEditor/MonacoJsonEditor";
import { InputTextSelf } from "../../../common/inputs/InputTextSelf";
import { SampleNodeBuildState } from "../../../../models/editor/SampleNode";
import { onChangeSampleNodeOutputHandler } from "../../../../applications/services/onChangeOutput";
import { NodeHeader } from "../common/NodeHeader";

export default function HTTPTriggerDetails(): ReactElement {
  const dispatch = useDispatch();

  const jwt = useAppSelector((state) => state.account.jwt);
  const currentWorkspace = useAppSelector(
    (state) => state.account.currentWorkspace
  );
  const flowVersion = useAppSelector((state) => state.flowEditor.flowVersion);
  const node = useAppSelector((state) =>
    state.flowEditor.nodes.find(
      (n) => n.uuid === state.flowEditor.nodeBeingEdited
    )
  ) as NodeHTTPTrigger;

  let sampleNode = useAppSelector((state) =>
    node ? state.flowEditor.sampleNodes[node.uuid] : undefined
  );
  let sampleUuid = useAppSelector((state) => state.flowEditor.sampleSelected);

  // Checkbox for manual editing
  // const [manualPayload, setManualPayload] = useState(false);

  if (!node || !flowVersion || !sampleUuid) {
    return <div></div>;
  }

  if (!sampleNode) {
    sampleNode = {
      nodeUuid: node.uuid,
      sampleUuid,
      flowVersionUuid: flowVersion.uuid,
      buildState: SampleNodeBuildState.INITIALIZED,
      input: "{}",
      output: "{}",
    };
  }

  // Callbacks
  async function onChangeOutput(value: string | undefined) {
    if (
      jwt &&
      currentWorkspace &&
      node &&
      flowVersion &&
      sampleNode &&
      sampleUuid &&
      value
    ) {
      await onChangeSampleNodeOutputHandler(
        dispatch,
        jwt,
        {
          flowUuid: flowVersion.flowUuid,
          flowVersionUuid: flowVersion.uuid,
          sampleUuid: sampleUuid,
          workspaceUuid: currentWorkspace.uuid,
        },
        sampleNode,
        node.technicalName,
        value
      );
    }
  }

  function onChangeName(v: string) {
    window.debug && console.log("onChangeName", v);
    if (jwt && currentWorkspace && node && flowVersion) {
      const nodeToUpdate: Node = {
        ...node,
        name: v,
      };
      dispatch(
        updateNodeThunk({
          userJWT: jwt,
          options: {
            flowUuid: flowVersion.flowUuid,
            flowVersionUuid: nodeToUpdate.flowVersionUuid,
            uuid: node.uuid,
            workspaceUuid: currentWorkspace.uuid,
          },
          body: nodeToUpdate,
        })
      );
    }
  }

  function onChangeTechnicalName(v: string) {
    return;
    // if (jwt && currentWorkspace && node && flowVersion && v) {
    //   const nodeToUpdate: Node = {
    //     ...node,
    //     technicalName: v,
    //   };
    //   dispatch(
    //     updateNodeThunk({
    //       userJWT: jwt,
    //       options: {
    //         flowUuid: flowVersion.flowUuid,
    //         flowVersionUuid: nodeToUpdate.flowVersionUuid,
    //         uuid: node.uuid,
    //         workspaceUuid: currentWorkspace.uuid,
    //       },
    //       body: nodeToUpdate,
    //     })
    //   );
    // }
  }

  // function onChangeManualPayload(e: MouseEvent) {
  //   setManualPayload(!manualPayload);
  // }

  let outputAsObject = {};
  try {
    outputAsObject = JSON.parse(sampleNode.output);
  } catch (error) {
    // Do nothing
  }

  const outputFormatted = JSON.stringify(outputAsObject, null, 2);

  let outputDom;
  // If not empty
  if (outputFormatted !== "{}") {
    outputDom = (
      <MonacoJsonEditor
        size={{ height: "50vh" }}
        readOnly={true}
        value={outputFormatted}
        onChange={onChangeOutput}
        path={JSON_SCHEMA_URI_MAP.HTTP_TRIGGER_INPUT?.toString()}
      ></MonacoJsonEditor>
    );
  } else {
    // Else show empty state
    outputDom = (
      <div className="node-waiting">
        <span className="node-waiting__loader">
          <span>Waiting for input</span>
          <div className="hktf-loader">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </span>
      </div>
    );
  }

  return (
    <div className="builder-node-details__container">
      <NodeHeader node={node}></NodeHeader>
      <div className="builder-node-details__technical-name">
        Name:{" "}
        <InputTextSelf
          onAccept={onChangeName}
          value={node.name ?? ""}
        ></InputTextSelf>
      </div>
      <div className="builder-node-details__technical-name">
        Unique identifier:{" "}
        <InputTextSelf
          onAccept={onChangeTechnicalName}
          value={node.technicalName ?? ""}
        ></InputTextSelf>
      </div>
      <div className="builder-node-details__content">
        <p>
          The HTTP Trigger node is a start node. It launches a new execution of
          the flow as soon as it receives a POST request. You can use the
          following test URL to configure this flow.
        </p>
        <div className="builder-node-details__input-group">
          <input
            className="builder-node-details__input-text"
            type="text"
            disabled
            value={
              flowVersion ? generateURLForVersion(flowVersion) : "Missing URL"
            }
          />
          <span className="builder-node-details__input-note">
            Note that this URL is linked to the current version of this flow and
            can only be used for building purpose.
          </span>
        </div>
        <h3 className="builder-node-details__subtitle">Payload received</h3>
        {outputDom}
        {/* <div className="builder-node-details__input-group">
          <span>
            Provide the payload manually{" "}
            <Checkbox onClick={onChangeManualPayload}></Checkbox>
          </span>
          <span className="builder-node-details__input-note">
            By using this option, you can insert your own payload following the{" "}
            <a href="#">conventions defined here</a>.
          </span>
        </div> */}
      </div>
    </div>
  );
}
